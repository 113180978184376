import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";
import { useNavigate } from "react-router";

const SecondFooter = () => {
  const date = new Date();
  const navigate = useNavigate();
  const goTo = (link) => {
    const releventDiv = document.getElementById(link);
    if (releventDiv) {
      releventDiv.scrollIntoView({ behavior: "smooth" });
    } else {
      navigate("/");
    }
  };

  return (
    <footer className="bg-[#ff7900] text-white py-12">
      <div className="container mx-auto px-6 grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Brand Section */}
        <div className="space-y-4">
          <h1 className="text-3xl font-heading font-bold italic text-white">
            Rammohan Naidu Kinjarapu
          </h1>
          <h5 className="font-paragraph font-bold text-xl">
            Honourable Minister of Civil Aviation
          </h5>
          <p className="text-lg font-paragraph text-gray-200">
            Leading innovation and progress in India's aviation sector through
            sustainable development and regional connectivity.
          </p>
        </div>

        {/* Navigation Section */}
        <div className="space-y-4 lg:ml-48">
          <h3 className="text-lg font-bold font-heading">Navigation</h3>
          <ul className="space-y-2">
            <li>
              <a
                href="/about"
                className="text-sm hover:underline font-paragraph"
              >
                About
              </a>
            </li>
            <li>
              <a
                href="/news"
                className="text-sm hover:underline font-paragraph"
              >
                Latest News
              </a>
            </li>
            <li>
              <a
                href="/gallery"
                className="text-sm hover:underline font-paragraph"
              >
                Gallery
              </a>
            </li>
            <li>
              <a
                href="/team"
                className="text-sm hover:underline font-paragraph"
              >
                AMO
              </a>
            </li>
            <li>
              <a
                href="/scorecard"
                className="text-sm hover:underline font-paragraph"
              >
                Scorecard
              </a>
            </li>
            <li>
              <a
                href="https://seva.rammnk.com/"
                 target="__blank"
                className="text-sm hover:underline font-paragraph"
              >
                SEVA
              </a>
            </li>
          </ul>
        </div>
      
        {/* Contact Info Section */}
        <div className="space-y-4 lg:ml-24">
          <h3 className="text-lg font-bold font-heading">Contact Info</h3>
          <p className="text-sm font-paragraph">
            <strong>Web:</strong>{" "}
            <a
              href="https://rammnk.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline"
            >
              https://rammnk.com
            </a>
          </p>
          <p className="text-sm font-paragraph">
            <strong>Phone:</strong> 24610350 / 24632991
          </p>
          {/* <p className="text-sm font-paragraph">
            <strong>Email:</strong>{" "}
            <a
              href="mailto:info@rammnk.com"
              className="hover:underline"
            >
              info@rammnk.com
            </a>
          </p> */}
        </div>
      </div>

      {/* Divider */}
      <div className="mt-8 border-t border-gray-200"></div>

      {/* Bottom Section */}
      <div className="container mx-auto px-6 mt-8 flex flex-col md:flex-row justify-between items-center">
        <p className="text-sm font-paragraph">
          © {date.getFullYear()} Rammohan Naidu. All rights reserved.
        </p>
        <div className="flex space-x-6 mt-4 md:mt-0">
          <a
            href="https://www.facebook.com/RamMNK"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-300 hover:text-white transition-colors"
          >
            <FaFacebookF size={20} />
          </a>
          <a
            href="https://x.com/rammnk"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-300 hover:text-white transition-colors"
          >
            <FaTwitter size={20} />
          </a>
          <a
            href="https://www.instagram.com/rammnk"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-300 hover:text-white transition-colors"
          >
            <FaInstagram size={20} />
          </a>
          <a
            href="https://www.youtube.com/@RamMNK"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-300 hover:text-white transition-colors"
          >
            <FaYoutube size={20} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default SecondFooter;
