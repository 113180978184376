import React from "react";
import ReactPlayer from "react-player";

const VideoPlayer = () => {
  return (
    <>
      <div className="flex bg-gray-50 w-full p-2 justify-center items-center min-h-[400px]">
        <div className="w-full max-w-5xl">
          
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold font-heading text-gray-800 mb-4">
            Watch Our Video
          </h2>
          <p className="text-gray-700 font-paragraph text-lg">
          Discover more insights and updates by watching our video.
          </p>
          
        </div>
          
          <div className="relative pb-[56.25%] h-0 overflow-hidden rounded-xl">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=k76sdzVly48"
              width="100%"
              height="100%"
              controls
              className="absolute top-0 left-0 rounded-xl"
            />
          </div>
         
        </div>
      </div>
    </>
  );
};

export default VideoPlayer;
