import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const languages = [
  { code: 'te', lang: 'Telugu' },
  { code: 'en', lang: 'English' },
];

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div
      class="flex justify-end items-center py-2 bg-[#1A1423]"
    >
      <label class="text-white mr-2">Choose a language: </label>
      <select
        name="lang_choice"
        className="mr-2 rounded-2xl "
        onChange={(e) => changeLanguage(e.target.value)}
      >
        {languages.map((lng) => {
          return (
            <option key={lng.code} value={lng.code} selected="selected">
              {lng.lang}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default LanguageSelector;
