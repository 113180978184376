import { useEffect, useState } from "react";
import { get } from "lodash";
import { Controller, useForm } from "react-hook-form";
import InputField from "../.././Common/InputField";
import CheckboxField from "../.././Common/CheckboxField";
import { Grid, Button } from "@mui/material";
import TicketCharter from ".././Ticket-Notes/TicketCharter";
import CustomModal from "../../Common/CustomModal";
import DateField from "../../Common/DateField";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  postRequest,
  putRequest,
  getRequest,
} from ".././interceptor/interceptor";
import FullScreenLoader from ".././Loader/FullScreenLoader";
import { UPDATE_BUDGET_NEWS, DOCUMENT_UPLOAD } from "../.././apiConst";
import UploadField from "../../Common/UploadField";
import { State, City } from "country-state-city";
import { useLocation, useNavigate } from "react-router";
import SelectDropdown from "../.././Common/SelectDropdown";
import { newsFields } from "../.././Fields/newsFields";
import CkEditorComponent from "../.././Common/CkEditor";
import moment from "moment";
import { followUpFields } from "../../Fields/grievanceFields";
import { useDispatch, useSelector } from "react-redux";
import {
  setTicketDetails,
  setTrackingStateIndex,
} from "../../redux/actions/trackingState.action";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { toast } from "react-toastify";

const Form = ({ disabled }) => {
  console.log("disabled", disabled);
  const trackingState = useSelector((state) => state.tracking.trackingState);
  const ticketDetails = useSelector((state) => state.tracking.ticketDetails);
  const dispatch = useDispatch();
  const location = useLocation();
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const fields = [...newsFields];
  const [id, setId] = useState(null);
  const [isDisabledTicket, setIsDisabledTicket] = useState(false);
  const [newsId, setNewsId] = useState(
    location?.state?.data?.task?.newsId || null
  );

  const [minDate, setMinDate] = useState("");

  const [isTicketToggle, setIsTicketToggle] = useState(true); // State to toggle the accordion

  const ticketToggleAccordion = () => {
    setIsTicketToggle(!isTicketToggle);
  };

  const [modal, setModal] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    control,
    formState: { errors },
  } = useForm({});

  const { task } = location?.state?.data;

  useEffect(() => {
    const { task } = location?.state?.data;
    console.log("task", task);
    // Set form fields using setValue
    setValue("uploadFile", task.uploadFile);
    setValue("uploadFileName", task.uploadFileName);
    setImage(task?.image);
    fields.map((field) => setValue(field.fieldName, task[field.fieldName]));
    if (task?.status === "new") {
      dispatch(setTrackingStateIndex(Number(0)));
    } else if (task?.status === "acknowledged") {
      dispatch(setTrackingStateIndex(Number(1)));
    } else {
      dispatch(setTrackingStateIndex(Number(1)));
    }
  }, []);
  const onSubmit = (data, e) => {
    if (e.target.value === "acknowledged") {
      data = {
        status: "acknowledged",
        newsId: data?.newsId,
        title: data?.title,
        description: data?.description,
        link: data?.link,
        module: "news",
        image: image,
      };
      let url = "";
      url = putRequest(`${UPDATE_BUDGET_NEWS}`, data);
      url
        .then((res) => {
          setIsLoading(false);
          navigate("/news");
        })
        .catch((res) => {
          setIsLoading(false);
        });
      setIsLoading(false);
    } else {
      data = {
        status: task?.status,
        newsId: data?.newsId,
        title: data?.title,
        description: data?.description,
        link: data?.link,
        module: "news",
        image: image,
      };
      let url = "";

      console.log("data", data);
      url = putRequest(`${UPDATE_BUDGET_NEWS}`, data);
      url
        .then((res) => {
          setIsLoading(false);
          navigate("/news");
        })
        .catch(() => {
          setIsLoading(false);
        });
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const onUpload = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const file = e.target.files[0];
    const formData = new FormData();

    formData.append("file", file);
    setValue("uploadFile", "");
    setValue("uploadFileName", "");

    postRequest(`${DOCUMENT_UPLOAD}`, formData, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        console.log("res", res);
        const { url, fileName } = res.data.data[0];
        setValue("uploadFile", url);
        setValue("uploadFileName", fileName);
        setImage(url);
        setTimeout(() => {
          setIsLoading(false);
        }, 100);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const onRemoveFile = (e) => {
    setValue("uploadFile", "");
    setValue("uploadFileName", "");
    setImage("");
  };

  const pressCloseButton = () => {
    // reset();
    setModal(!modal);
  };
  const handleOpenModal = () => {
    // reset({followUpEmail:'',followUpNote:""})
    setModal(!modal);
  };

  const submitBtns = () => {
    return (
      <div className="flex flex-col lg:flex-row items-center justify-start space-x-4 mb-4">
        {trackingState === 0 ? (
          <>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="mt-5"
              value="acknowledged"
              onClick={handleSubmit(onSubmit)}
            >
              Acknowledge
            </Button>
            {/* <Grid item>
              <Button
                variant="contained"
                color="secondary"
                value="save"
                onClick={handleSubmit(onSubmit)}
                className="mt-5"
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                value="submit"
                onClick={handleSubmit(onSubmit)}
                className="mt-5"
              >
                Save & Submit
              </Button>
            </Grid> */}
          </>
        ) : trackingState === 1 ? (
          <>
            {/* <Grid item>
              <Button
                variant="contained"
                color="secondary"
                value="save"
                onClick={handleSubmit(onSubmit)}
                className="mt-5"
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="mt-5"
                value="acknowledged"
                onClick={handleSubmit(onSubmit)}
              >
                Acknowledged
              </Button>
            </Grid> */}
            {/* <Grid item>
              <Button
                type="submiit"
                variant="contained"
                color="error"
                className="mt-5"
                value="rejected"
                onClick={handleSubmit(onSubmit)}
              >
                Reject
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="success"
                className="mt-5"
                value="resolved"
                onClick={handleSubmit(onSubmit)}
              >
                Mark As Resolved
              </Button>
            </Grid> */}
          </>
        ) : trackingState === 2 && task?.ticketStatus === "approved" ? (
          <>
            {/* <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="success"
                className="mt-5"
                value="resolved"
                onClick={handleSubmit(onSubmit)}
              >
                Mark As Resolved
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="mt-5"
                value="submit"
                onClick={handleSubmit(onSubmit)}
              >
                Re-Open
              </Button>
            </Grid> */}
            {/* <Grid item>
              <Button
                // type="submit"
                variant="contained"
                color="secondary"
                className="mt-5"
                value="followUp"
                onClick={handleOpenModal}
                disabled={followUpDisabled}
              >
                Follow-Up
              </Button>
            </Grid> */}
          </>
        ) : trackingState === 2 && task?.ticketStatus === "rejected" ? (
          <Grid item>
            {/* <Button
              type="submit"
              variant="contained"
              color="primary"
              className="mt-5"
              value="submit"
              onClick={handleSubmit(onSubmit)}
            >
              Re-Open
            </Button> */}
          </Grid>
        ) : null}
      </div>
    );
  };

  const ModalBody = () => {
    return (
      <>
        {isLoading && <FullScreenLoader />}
        <form className="w-full flex justify-center items-center flex-col p-5">
          <div className="w-full grid md:grid-cols-1 gap-6">
            {followUpFields?.map((field) => {
              const {
                fieldTitle,
                fieldName,
                fieldType,
                validationObj,
                disabled,
              } = field;
              return (
                <div className="flex flex-col w-full" key={fieldName}>
                  <label className="block text-gray-800 font-bold mb-3 pr-4">
                    {fieldTitle}
                  </label>
                  <div className="w-full flex items-center">
                    <div className="w-full">
                      {fieldType === "date" ? (
                        <>
                          <DateField
                            fieldName={fieldName}
                            validationObj={validationObj}
                            control={control}
                            Controller={Controller}
                            minDate={minDate}
                            errors={errors}
                            defaultValue={minDate}
                          />
                        </>
                      ) : (
                        <InputField
                          fieldName={fieldName}
                          validationObj={validationObj}
                          register={register}
                          errors={errors}
                          fieldType={fieldType}
                          disabled={disabled}
                          placeholder={fieldTitle}
                          className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring focus:ring-primary-300"
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="flex justify-center">
              <Button
                type="button"
                variant="contained"
                color="error"
                className="mt-4 px-6 py-2 rounded-md mr-2 text-white transition duration-150"
                onClick={pressCloseButton}
              >
                Cancel
              </Button>
              <Button
                type="button"
                variant="contained"
                color="primary"
                value="followUp"
                className="mt-4 px-6 py-2 rounded-md ml-2 text-white transition duration-150 "
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </>
    );
  };

  return (
    <>
      <CustomModal
        modal={modal}
        body={<ModalBody />}
        heading="Follow-Up"
        pressCloseButton={pressCloseButton}
      />
      {isLoading && <FullScreenLoader />}
      <div className="block rounded-lg bg-white p-4 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] m-4 overflow-auto">
        {/* <div className="flex mb-2 text-xl font-extrabold text-primary-600">
          {isEdit ? "Edit" : "Add"} Ticket
        </div> */}
        {task?.ticketStatus === "approved" ||
        task?.ticketStatus === "rejected" ? (
          <p className="text-2xl mb-2 mt-2">
            Status:{" "}
            <span
              className={`color: ${
                task?.ticketStatus === "approved"
                  ? "text-green-500"
                  : "text-red-500"
              } capitalize`}
            >
              {task?.ticketStatus}
            </span>
          </p>
        ) : null}
        {/* {
          <p className="text-2xl mb-2 mt-2">
            Request Description:{' '}
            <span className="text-blue-600">{task?.requestDescription}</span>
          </p>
        } */}
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* {!location?.state?.isView ? submitBtns() : null} */}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div
                className="flex justify-between items-center text-white rounded-md bg-gray-700 p-2 mb-1 cursor-pointer"
                onClick={ticketToggleAccordion} // Toggle on click
              >
                <h2>News Details</h2>
                {/* Render the icon based on the toggle state */}
                {isTicketToggle ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </Grid>
            {isTicketToggle &&
              newsFields?.map((field) => {
                const {
                  fieldTitle,
                  fieldName,
                  fieldType,
                  validationObj,
                  options,
                } = field;

                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      lg={fieldType === "textarea" ? 12 : 4}
                      key={fieldName}
                    >
                      {fieldType === "upload" ? (
                        <div className="">
                          <label className="block text-gray-800 text-sm font-bold mb-3 pr-4">
                            {fieldTitle}
                          </label>
                          <UploadField
                            fieldName={fieldName}
                            validationObj={validationObj}
                            register={register}
                            errors={errors}
                            fieldType={fieldType}
                            disabled={location?.state?.isView}
                            onChange={onUpload}
                            accept="image/*"
                            onRemoveFile={onRemoveFile}
                            valueClassName={"flex m-2 items-center"}
                            value={
                              getValues("uploadFileName") !== ""
                                ? getValues("uploadFileName")
                                : ""
                            }
                            // onBlur={onFieldBlur}
                            placeholder={fieldTitle}
                          />
                        </div>
                      ) : (
                        <>
                          <label className="block text-gray-800 text-sm font-bold mb-3 pr-4">
                            {fieldTitle}
                          </label>
                          <InputField
                            fieldName={fieldName}
                            validationObj={validationObj}
                            register={register}
                            disabled={
                              fieldName === "newsId"
                                ? true
                                : location?.state?.isView
                            }
                            errors={errors}
                            fieldType={fieldType}
                          />
                        </>
                      )}
                    </Grid>
                  </>
                );
              })}
            {image !== "" && (
              <div className="mt-4">
                <img
                  src={image}
                  alt="Uploaded file preview"
                  className="w-32 h-32 object-cover rounded-md border border-gray-300"
                />
                <Button
                  variant="contained"
                  color="error"
                  className="mt-2"
                  disabled={location?.state?.isView}
                  onClick={onRemoveFile}
                >
                  Remove Image
                </Button>
              </div>
            )}
          </Grid>
          <TicketCharter
            taskId={id}
            ticketNo={newsId}
            isView={location?.state?.isView}
            noteStatus="inprogress"
          />
          {!location?.state?.isView ? submitBtns() : null}
        </form>
      </div>
    </>
  );
};

export default Form;
