import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { getRequest } from "../interceptor/interceptor";
import { LATEST_NEWS } from "../../apiConst";

const Section = () => {
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    getNewsData();
  }, []);

  const getNewsData = () => {
    getRequest(`${LATEST_NEWS}`)
      .then((res) => {
        if (res) {
          setBlogData(res.data);
        }
      })
      .catch(() => {
        setBlogData([]);
      });
  };

  const goToLink = (link) => {
    window.open(link, "_blank");
  };

  return (
    <section id="news" className="bg-white py-16">
      <div className="container mx-auto">
        {/* Header Section */}
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold font-heading text-gray-800 mb-4">
            Latest News
          </h2>
          <p className="text-gray-700 font-paragraph text-lg">
            To make sure all Citizen rights, you have to work together and make <br/>
            better country for our child. So we have to take initiative proper.
          </p>
          
        </div>

        {/* Blog Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 px-5 lg:px-0">
          {blogData.map((blog) => (
            <div
              key={blog.title}
              className="overflow-hidden bg-white flex flex-col"
            >
              <div className="relative">
                <img
                  src={blog.photo}
                  alt={`Blog ${blog.title}`}
                  className="w-full h-56 object-cover"
                />
              </div>
              <div className="p-6 flex flex-col flex-grow">
                {/* <p className="text-sm text-gray-500 mb-2 font-light">
                  By: <span className="font-medium text-gray-800">{blog.author}</span> - {blog.date}
                </p> */}
                <h3 className="text-xl font-semibold font-heading text-gray-800 mb-4">
                  {blog.title.length > 60
                    ? `${blog.title.substring(0, 60)}...`
                    : blog.title}
                </h3>
                <p className="text-lg font-paragraph text-gray-700">{blog.description.length > 120
                    ? `${blog.description.substring(0, 120)}...`
                    : blog.description}</p>
                <div className="mt-auto">
                  <button
                    onClick={() => goToLink(blog.link)}
                    className="text-gray-900 font-medium underline hover:bg-transparent hover:text-red-900"
                  >
                    Read more
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Section;