import SecondFooter from "../Footer/SecondFooter";
import Navbar from "../Navbar/navbar";

const Scorecard = () => {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Navbar */}
      <Navbar />

      {/* Main Content */}
      <main className="flex-grow">
        <section className="px-5">
          <div className="container mx-auto mb-12">
            {/* Header Section */}
            <div className="text-center mt-12 mb-12">
              <h2 className="text-4xl font-bold font-heading text-gray-800 mb-4">
                Achievements
              </h2>
              <p className="text-gray-700 font-paragraph text-lg">
                Celebrate the milestones and accomplishments we’ve reached
                together.
              </p>
            </div>
            <div className="flex flex-col justify-between lg:grid lg:grid-cols-2 lg:gap-2"></div>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="mt-auto">
        <SecondFooter />
      </footer>
    </div>
  );
};

export default Scorecard;
