import { useEffect, useState } from "react";
import { get } from "lodash";
import { Controller, useForm } from "react-hook-form";
import InputField from "../.././Common/InputField";
import CheckboxField from "../.././Common/CheckboxField";
import { Grid, Button } from "@mui/material";
import TicketCharter from ".././Ticket-Notes/TicketCharter";
import CustomModal from "../../Common/CustomModal";
import DateField from "../../Common/DateField";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  postRequest,
  putRequest,
  getRequest,
} from ".././interceptor/interceptor";
import FullScreenLoader from ".././Loader/FullScreenLoader";
import { UPDATE_BUDGET_NEWS } from "../.././apiConst";
import { State, City } from "country-state-city";
import { useLocation, useNavigate } from "react-router";
import SelectDropdown from "../.././Common/SelectDropdown";
import {
  grievanceStepperTicketFields,
  followUpFields,
} from "../.././Fields/grievanceFields";
import {
  budgetPersonFields,
  budgetRequestFields,
} from "../../Fields/budgetFields";
import CkEditorComponent from "../.././Common/CkEditor";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  setTicketDetails,
  setTrackingStateIndex,
} from "../../redux/actions/trackingState.action";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { toast } from "react-toastify";

const Form = ({ disabled }) => {
  console.log("disabled", disabled);
  const trackingState = useSelector((state) => state.tracking.trackingState);
  const ticketDetails = useSelector((state) => state.tracking.ticketDetails);
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const fields = [...budgetPersonFields, ...budgetRequestFields];
  const ticketFields = grievanceStepperTicketFields;
  const [id, setId] = useState(null);
  const [isDisabledTicket, setIsDisabledTicket] = useState(false);
  const [requestNumber, setrequestNumber] = useState(
    location?.state?.data?.task?.requestNumber || null
  );

  const [minDate, setMinDate] = useState("");
  const [followUpDisabled, setFollowUpDisabled] = useState(false);

  const [isTicketToggle, setIsTicketToggle] = useState(true); // State to toggle the accordion
  const [isRequestToggle, setIsRequestToggle] = useState(false); // State to toggle the accordion

  const ticketToggleAccordion = () => {
    setIsTicketToggle(!isTicketToggle);
    setIsRequestToggle(false);
  };

  const requestToggleAccordion = () => {
    setIsRequestToggle(!isRequestToggle);

    setIsTicketToggle(false);
  };

  const [modal, setModal] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    control,
    formState: { errors },
  } = useForm({});

  const { task } = location?.state?.data;

  useEffect(() => {
    const { task } = location?.state?.data;
    setFollowUpDisabled(task?.followup);
    console.log("task", task);
    // Set form fields using setValue
    fields.map((field) => setValue(field.fieldName, task[field.fieldName]));

    if (task?.status === "new") {
      dispatch(setTrackingStateIndex(Number(0)));
    } else if (task?.status === "inprogress") {
      dispatch(setTrackingStateIndex(Number(1)));
    } else if (task?.status === "approved") {
      dispatch(setTrackingStateIndex(Number(2)));
    } else if (task?.status === "rejected") {
      dispatch(setTrackingStateIndex(Number(2)));
    } else {
      dispatch(setTrackingStateIndex(Number(3)));
    }
  }, []);
  const onSubmit = (data, e) => {
    if (e.target.value === "approved") {
      data = {
        ...data,
        status: "approved",
        module: "budget",
        requestedAmount: Number(data.requestedAmount),
        grantedAmount: Number(data.grantedAmount),
        requestedDate: moment(data.requestedDate).format("YYYY-MM-DD"),
        grantedDate: moment(data.grantedDate).format("YYYY-MM-DD"),
      };
      let url = "";
      url = putRequest(`${UPDATE_BUDGET_NEWS}`, data);
      url
        .then((res) => {
          setIsLoading(false);
          navigate("/budget");
        })
        .catch((res) => {
          setIsLoading(false);
        });
      setIsLoading(false);
    } else if (e.target.value === "rejected") {
      data = {
        ...data,

        status: "rejected",
        module: "budget",
        requestedAmount: Number(data.requestedAmount),
        grantedAmount: Number(data.grantedAmount),
        requestedDate: moment(data.requestedDate).format("YYYY-MM-DD"),
        grantedDate: moment(data.grantedDate).format("YYYY-MM-DD"),
      };
      let url = "";
      url = putRequest(`${UPDATE_BUDGET_NEWS}`, data);
      url
        .then((res) => {
          setIsLoading(false);
          navigate("/budget");
        })
        .catch(() => {
          setIsLoading(false);
        });
      setIsLoading(false);
    } else if (e.target.value === "resolved") {
      data = {
        ...data,
        status: "resolved",
        module: "budget",
        requestedAmount: Number(data.requestedAmount),
        grantedAmount: Number(data.grantedAmount),
        requestedDate: moment(data.requestedDate).format("YYYY-MM-DD"),
        grantedDate: moment(data.grantedDate).format("YYYY-MM-DD"),
      };
      let url = "";
      url = putRequest(`${UPDATE_BUDGET_NEWS}`, data);
      url
        .then((res) => {
          setIsLoading(false);
          navigate("/budget");
        })
        .catch(() => {
          setIsLoading(false);
        });
      setIsLoading(false);
    } else if (e.target.value === "submit") {
      data = {
        ...data,
        status: "inprogress",
        module: "budget",
        requestedAmount: Number(data.requestedAmount),
        grantedAmount: Number(data.grantedAmount),
        requestedDate: moment(data.requestedDate).format("YYYY-MM-DD"),
        grantedDate: moment(data.grantedDate).format("YYYY-MM-DD"),
      };
      let url = "";
      url = putRequest(`${UPDATE_BUDGET_NEWS}`, data);
      url
        .then((res) => {
          setIsLoading(false);
          navigate("/budget");
        })
        .catch(() => {
          setIsLoading(false);
        });
      setIsLoading(false);
    } else if (e.target.value === "followUp") {
      // setFollowUpDisabled(true);
      // data = {
      //   ...data,
      //   assignedTo: data?.assignedTo,
      //   priority: data?.priority,
      //   ttdAvailableSlot:
      //     data?.personalRequestType !== 'TTD Darshan'
      //       ? ''
      //       : data?.ttdAvailableSlot,
      //   followup: true,
      //   followupData: {
      //     date: data.followUpDate,
      //     comment: data.followUpNote,
      //     email: data.followUpEmail,
      //   },
      // };
      // delete data.followUpDate;
      // delete data.followUpNote;
      // delete data.followUpEmail;
      // let url = '';
      // url = putRequest(`${UPDATE_BUDGET_NEWS}`, data);
      // url
      //   .then((res) => {
      //     setIsLoading(false);
      //     setModal(false);
      //   })
      //   .catch(() => {
      //     setIsLoading(false);
      //   });
      setIsLoading(false);
    } else {
      data = {
        ...data,
        status: task?.status,
        module: "budget",
        requestedAmount: Number(data.requestedAmount),
        grantedAmount: Number(data.grantedAmount),
        requestedDate: moment(data.requestedDate).format("YYYY-MM-DD"),
        grantedDate: moment(data.grantedDate).format("YYYY-MM-DD"),
      };
      let url = "";

      console.log("data", data);
      url = putRequest(`${UPDATE_BUDGET_NEWS}`, data);
      url
        .then((res) => {
          setIsLoading(false);
          navigate("/budget");
        })
        .catch(() => {
          setIsLoading(false);
        });
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const pressCloseButton = () => {
    // reset();
    setModal(!modal);
  };
  const handleOpenModal = () => {
    // reset({followUpEmail:'',followUpNote:""})
    setModal(!modal);
  };

  const submitBtns = () => {
    return (
      <div className="flex flex-col lg:flex-row items-center justify-start space-x-4 mb-4">
        {trackingState === 0 ? (
          <>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                value="save"
                onClick={handleSubmit(onSubmit)}
                className="mt-5"
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                value="submit"
                onClick={handleSubmit(onSubmit)}
                className="mt-5"
              >
                Save & Submit
              </Button>
            </Grid>
          </>
        ) : trackingState === 1 ? (
          <>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                value="save"
                onClick={handleSubmit(onSubmit)}
                className="mt-5"
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="mt-5"
                value="approved"
                onClick={handleSubmit(onSubmit)}
              >
                Approve
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submiit"
                variant="contained"
                color="error"
                className="mt-5"
                value="rejected"
                onClick={handleSubmit(onSubmit)}
              >
                Reject
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="success"
                className="mt-5"
                value="resolved"
                onClick={handleSubmit(onSubmit)}
              >
                Mark As Resolved
              </Button>
            </Grid>
          </>
        ) : trackingState === 2 && task?.status === "approved" ? (
          <>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="success"
                className="mt-5"
                value="resolved"
                onClick={handleSubmit(onSubmit)}
              >
                Mark As Resolved
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="mt-5"
                value="submit"
                onClick={handleSubmit(onSubmit)}
              >
                Re-Open
              </Button>
            </Grid>
            {/* <Grid item>
              <Button
                // type="submit"
                variant="contained"
                color="secondary"
                className="mt-5"
                value="followUp"
                onClick={handleOpenModal}
                disabled={followUpDisabled}
              >
                Follow-Up
              </Button>
            </Grid> */}
          </>
        ) : trackingState === 2 && task?.status === "rejected" ? (
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="mt-5"
              value="submit"
              onClick={handleSubmit(onSubmit)}
            >
              Re-Open
            </Button>
          </Grid>
        ) : null}
      </div>
    );
  };

  const ModalBody = () => {
    return (
      <>
        {isLoading && <FullScreenLoader />}
        <form className="w-full flex justify-center items-center flex-col p-5">
          <div className="w-full grid md:grid-cols-1 gap-6">
            {followUpFields?.map((field) => {
              const {
                fieldTitle,
                fieldName,
                fieldType,
                validationObj,
                disabled,
              } = field;
              return (
                <div className="flex flex-col w-full" key={fieldName}>
                  <label className="block text-gray-800 font-bold mb-3 pr-4">
                    {fieldTitle}
                  </label>
                  <div className="w-full flex items-center">
                    <div className="w-full">
                      {fieldType === "date" ? (
                        <>
                          <DateField
                            fieldName={fieldName}
                            validationObj={validationObj}
                            control={control}
                            Controller={Controller}
                            minDate={minDate}
                            errors={errors}
                            defaultValue={minDate}
                          />
                        </>
                      ) : (
                        <InputField
                          fieldName={fieldName}
                          validationObj={validationObj}
                          register={register}
                          errors={errors}
                          fieldType={fieldType}
                          disabled={disabled}
                          placeholder={fieldTitle}
                          className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring focus:ring-primary-300"
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="flex justify-center">
              <Button
                type="button"
                variant="contained"
                color="error"
                className="mt-4 px-6 py-2 rounded-md mr-2 text-white transition duration-150"
                onClick={pressCloseButton}
              >
                Cancel
              </Button>
              <Button
                type="button"
                variant="contained"
                color="primary"
                value="followUp"
                className="mt-4 px-6 py-2 rounded-md ml-2 text-white transition duration-150 "
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
        
      </>
    );
  };

  return (
    <>
      <CustomModal
        modal={modal}
        body={<ModalBody />}
        heading="Follow-Up"
        pressCloseButton={pressCloseButton}
      />
      {isLoading && <FullScreenLoader />}
      <div className="block rounded-lg bg-white p-4 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] m-4 overflow-auto">
        {/* <div className="flex mb-2 text-xl font-extrabold text-primary-600">
          {isEdit ? "Edit" : "Add"} Ticket
        </div> */}
        {task?.status === "approved" || task?.status === "rejected" ? (
          <p className="text-2xl mb-2 mt-2">
            Status:{" "}
            <span
              className={`color: ${
                task?.status === "approved" ? "text-green-500" : "text-red-500"
              } capitalize`}
            >
              {task?.status}
            </span>
          </p>
        ) : null}
        {/* {
          <p className="text-2xl mb-2 mt-2">
            Request Description:{' '}
            <span className="text-blue-600">{task?.requestDescription}</span>
          </p>
        } */}
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* {!location?.state?.isView ? submitBtns() : null} */}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div
                className="flex justify-between items-center text-white rounded-md bg-gray-700 p-2 mb-1 cursor-pointer"
                onClick={ticketToggleAccordion} // Toggle on click
              >
                <h2>Request Information</h2>
                {/* Render the icon based on the toggle state */}
                {isTicketToggle ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </Grid>
            {isTicketToggle &&
              budgetPersonFields?.map((field) => {
                const {
                  fieldTitle,
                  fieldName,
                  fieldType,
                  validationObj,
                  options,
                } = field;

                return (
                  <>
                    <Grid item xs={12} lg={3} key={fieldName}>
                      {fieldType === "date" && fieldName === "issueOpen" ? (
                        <>
                          <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                            {fieldTitle}
                          </label>
                          <DateField
                            fieldName={fieldName}
                            validationObj={validationObj}
                            control={control}
                            Controller={Controller}
                            minDate={new Date()}
                            errors={errors}
                            defaultValue={getValues(fieldName)}
                            disabled={location?.state?.isView}
                          />
                        </>
                      ) : fieldType === "date" &&
                        fieldName === "targetResolution" ? (
                        <>
                          <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                            {fieldTitle}
                          </label>
                          <DateField
                            fieldName={fieldName}
                            validationObj={validationObj}
                            control={control}
                            Controller={Controller}
                            // minDate={issueOpen}
                            errors={errors}
                            defaultValue={getValues(fieldName)}
                            disabled={location?.state?.isView}
                          />
                        </>
                      ) : (
                        <>
                          <label className="block text-gray-800 text-sm font-bold mb-3 pr-4">
                            {fieldTitle}
                          </label>
                          <InputField
                            fieldName={fieldName}
                            validationObj={validationObj}
                            register={register}
                            disabled={
                              fieldName === "requestNumber"
                                ? true
                                : location?.state?.isView
                            }
                            errors={errors}
                            fieldType={fieldType}
                          />
                        </>
                      )}
                    </Grid>
                  </>
                );
              })}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div
                className="flex justify-between items-center text-white rounded-md bg-gray-700 p-2 mb-1 cursor-pointer"
                onClick={requestToggleAccordion} // Toggle on click
              >
                <h2> Request Details</h2>
                {/* Render the icon based on the toggle state */}
                {isRequestToggle ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </Grid>
            {isRequestToggle &&
              budgetRequestFields.map((field) => {
                const {
                  fieldTitle,
                  fieldName,
                  fieldType,
                  validationObj,
                  options,
                } = field;

                return (
                  <Grid item xs={12} lg={3} key={fieldName}>
                    {fieldType === "date" ? (
                      <>
                        <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                          {fieldTitle}
                        </label>
                        <DateField
                          fieldName={fieldName}
                          validationObj={validationObj}
                          control={control}
                          Controller={Controller}
                          minDate={new Date()}
                          errors={errors}
                          defaultValue={getValues(fieldName)}
                          disabled={location?.state?.isView}
                        />
                      </>
                    ) : fieldType === "editor" ? (
                      <>
                        <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                          {fieldTitle}
                        </label>
                        <CkEditorComponent
                          register={register}
                          fieldName={fieldName}
                          control={control}
                          data={getValues(fieldName) || ""}
                          setValue={setValue}
                          disabled={location?.state?.isView}
                        />
                      </>
                    ) : (
                      <>
                        <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                          {fieldTitle}
                        </label>
                        <InputField
                          fieldName={fieldName}
                          validationObj={validationObj}
                          register={register}
                          disabled={
                            fieldName === "requestNumber"
                              ? true
                              : location?.state?.isView
                          }
                          errors={errors}
                          fieldType={fieldType}
                        />
                      </>
                    )}
                  </Grid>
                );
              })}
          </Grid>
          <TicketCharter
            taskId={id}
            ticketNo={requestNumber}
            isView={location?.state?.isView}
            noteStatus="inprogress"
          />

          {!location?.state?.isView ? submitBtns() : null}
        </form>
      </div>
    </>
  );
};

export default Form;
