import LanguageSelector from '../../Common/LangauageSelector';
import About from '../About/About';
import TransformSection from '../transform/TransformSection';
import Gallery from '../Gallery/Gallery';
import SecondFooter from '../Footer/SecondFooter';
import HeroBanner from '../Hero/HeroBanner';
import Section from '../Section/Section';
import Navbar from '../Navbar/navbar';
import Feed from '../SocialFeed/Feed';
import HeroSection from '../Hero/HeroSection';
import VideoPlayer from '../videoplayer/Videoplayer';
import { Link, useNavigate,useLocation } from 'react-router-dom';
import BannerSection from '../banner/BannerSection';
import Abilities from '../abilities/Abilities';

const SecondHomePage = () => {
  const location = useLocation();
  
    // Conditionally set background class
    const isvissible = location.pathname === "/" ? true : true;
  return (
    <div className="bg-white">
      {/* <LanguageSelector /> */}
      {/* <div className="clear"></div> */}
     {isvissible&& <Navbar />}
      {/* <HeroBanner /> */}
      <HeroSection/>
      <TransformSection />
      <BannerSection/>
      <Section />
      <Abilities/>
      {/* <Gallery /> */}
      <VideoPlayer/>
      <Feed />
      <SecondFooter />
    </div>
  );
};
export default SecondHomePage;
