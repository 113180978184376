import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import OtpInput from 'react-otp-input';
import InputField from '../../Common/InputField';
import { FaGoogle } from 'react-icons/fa';
import FullScreenLoader from '../Loader/FullScreenLoader';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { toast } from 'react-toastify';
import Navbar from '../Navbar/navbar';
import Footer from '../Footer/index';
import {
  removeUserOp,
  userLoginGenOp,
  userLoginVerifyOp,
} from '../../redux/operations/user.operations';
import { setUser } from '../../redux/actions/login.action';
import LanguageSelector from '../../Common/LangauageSelector';

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [otpFieldDisplay, setOtpFieldDisplay] = useState(false);
  const [googleUser, setGoogleUser] = useState([]);
  const [profile, setProfile] = useState(null);
  const [OTP, setOTP] = useState();
  const dispatch = useDispatch();
  const handleChange = (value) => setOTP(value);

  useEffect(() => {
    if (googleUser && googleUser.access_token) {
      setIsLoading(true);
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${googleUser.access_token}`,
              Accept: 'application/json',
            },
          }
        )
        .then((res) => {
          setIsLoading(false);
          setProfile(res.data);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  }, [googleUser]);

  useEffect(() => {
    if (profile) {
      const data = { email: profile.email };
      loginViaEmailAndOTP(data);
    }
  }, [profile]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('userAuthToken')) {
      // navigate("/dashboard");
      navigate('/dashboard');
    }
  });

  const loginViaEmailAndOTP = (data) => {
    if (otpFieldDisplay) {
      data = { ...data, email };
      dispatch(removeUserOp()).then(() => {
        dispatch(userLoginVerifyOp(data))
          .then((res) => {
            dispatch(setUser(res.data.data));
            reset();
            toast.success(res.data.message);
            const { token } = res.data.data;
            if (token) {
              localStorage.setItem('userAuthToken', token);
            }
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            console.log('error', err);
            err.response.data?.message &&
              toast.error(err.response.data.message);
            console.log(err);
          });
      });
    } else {
      setEmail(data.email);
      dispatch(userLoginGenOp(data))
        .then((response) => {
          setOtpFieldDisplay(true);
          reset();
          toast.info(response.data.message);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          err.data?.message && toast.error(err.data.message);
          console.log(err);
        });
    }
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);
    loginViaEmailAndOTP(otpFieldDisplay ? { ...data, otp: OTP } : data);
  };

  const loginToGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => setGoogleUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error),
  });

  return (
    <>
      <section className="h-screen">
        {isLoading && <FullScreenLoader />}
        {/* <LanguageSelector /> */}
        <Navbar />
        <div
          className="flex items-center justify-center lg:justify-end md:pl-20 md:pr-20 pl-5 pr-5 min-h-screen bg-gradient-to-r from-blue-500 to-yellow-500"
          style={{
            backgroundImage:
              'url("https://taskresultspro.s3.amazonaws.com/image.jfif")',
            width: '100%',
            height: '100vh',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          <div className="flex flex-col lg:grid grid-cols-2 gap-2">
            <div className="hidden lg:flex flex-col justify-center">
              <h1 className="text-white font-playwrite text-xl lg:text-2xl">
                Welcome to the SEVA Portal
              </h1>
              <br />
              <h2 className="text-white font-playwrite text-base lg:text-xl lg:text-justify">
                – a dedicated platform for efficiently managing and resolving
                grievances. Through SEVA, users concerns will be submitted,
                monitor their status, and receive timely updates. This system
                upholds our commitment to transparency and responsiveness,
                ensuring that every concern is addressed with care and
                professionalism.
              </h2>
            </div>
            <div className="bg-white w-full sm:w-full h-auto min-h-screen-3/4 max-h-screen-3/4 rounded-3xl pr-10 pl-10 pt-20 pb-20 mb-4 shadow-xl">
              {otpFieldDisplay ? (
                <div className="">
                  <h1 className="text-2xl text-start md:text-center md:mr-36 font-bold text-gray-700 mb-4">
                    Enter OTP
                  </h1>
                </div>
              ) : (
                <div className="text-start">
                  <h1 className="text-2xl font-bold text-gray-700 mb-4">
                    Login With OTP
                  </h1>
                </div>
              )}
              <form onSubmit={handleSubmit(onSubmit)}>
                {otpFieldDisplay ? (
                  <div className="mb-6 flex justify-center">
                    <OtpInput
                      value={OTP}
                      onChange={handleChange}
                      numInputs={4}
                      inputType="tel"
                      name="otp"
                      renderSeparator={<span></span>}
                      inputStyle="inputStyle"
                      shouldAutoFocus={true}
                      renderInput={(props) => <input {...props} required />}
                    />
                  </div>
                ) : (
                  <div className="relative mb-6">
                    <InputField
                      fieldName="email"
                      register={register}
                      errors={errors}
                      validationObj={{
                        required: 'Please Enter Email',
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: 'Please enter a valid email',
                        },
                      }}
                      fieldType="email"
                      className="min-h-[auto] w-full rounded-xl border-1 bg-light py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none"
                      placeholder="Email or Phone Number"
                    />
                  </div>
                )}

                <div className="w-full flex flex-col items-center justify-center pb-3">
                  <button
                    type="submit"
                    className="w-full max-w-sm mb-3 inline-block rounded-3xl bg-blue-900 px-7 pt-3 pb-2.5 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    onClick={(e) => handleSubmit(onSubmit, e)}
                  >
                    {otpFieldDisplay ? 'Verify OTP' : 'Sign in'}
                  </button>
                  {!otpFieldDisplay && (
                    <div className="w-full flex flex-col items-center justify-center">
                      <div className="flex justify-center items-center">
                        <hr className="w-full border-1 border-solid border-black-900 my-3 mr-2" />
                        <p className="text-gray-500 mb-3 uppercase font-semibold">
                          OR
                        </p>
                        <hr className="w-full border-gray-300 my-3 ml-2" />
                      </div>
                      <button
                        type="button"
                        className="w-full flex justify-center items-center max-w-sm mb-3 p-2 rounded-3xl border border-blue-900 border-solid bg-white text-blue-900 px-7 pt-3 pb-2.5 text-sm font-medium uppercase leading-normal transition duration-150 ease-in-out hover:bg-blue-900 hover:text-white focus:bg-blue-900 focus:text-white focus:outline-none focus:ring-0 active:bg-blue-900 active:text-white"
                        data-te-ripple-init
                        data-te-ripple-color="light"
                        onClick={() => loginToGoogle()}
                      >
                        <FaGoogle className="h-4 w-4 mr-2" />
                        <span>Sign in with Google</span>
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
