import { FaUsers, FaTrophy } from "react-icons/fa";
import { useNavigate } from "react-router";

const BannerSection = () => {
  const navigate=useNavigate()
  return (
    <div className="bg-[#ff7900] py-12">
      <div className="container mx-auto px-6 flex flex-col md:flex-row justify-between items-center text-white">
        {/* Left Section */}
        <div className="text-center md:text-left cursor-pointer" onClick={()=>navigate('/team')}>
          <div className="flex flex-col justify-center items-center">
            <FaUsers className="text-4xl mb-4" />
            <h2 className="text-2xl font-heading font-bold mb-2">The Team</h2>
            <p className="text-lg font-paragraph text-gray-100">
              Meet the people who drive our success and share our vision.
            </p>
          </div>
        </div>

        {/* Divider */}
        <div className="hidden md:block h-48 border-l border-white mx-8"></div>

        {/* Right Section */}
        <div className="text-center md:text-right">
          <div className="flex flex-col justify-center items-center cursor-pointer" onClick={()=>navigate('/scorecard')}>
            <FaTrophy className="text-4xl mb-4" />
            <h2 className="text-2xl font-heading font-bold mb-2">
              Achievements
            </h2>
            <p className="text-lg font-paragraph text-gray-100">
              Celebrate the milestones and accomplishments we’ve reached
              together.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerSection;
