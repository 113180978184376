import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { userLogoutOp } from '../../redux/operations/user.operations';
import { motion } from 'framer-motion';

// * React icons
import { IoIosArrowBack } from 'react-icons/io';
import { FaRegCalendarDays } from 'react-icons/fa6';
import { AiOutlineAppstore, AiOutlineLogout } from 'react-icons/ai';
import { useMediaQuery } from 'react-responsive';
import { FaTasks } from 'react-icons/fa';
import { CiDollar } from "react-icons/ci";
import { MdMenu } from 'react-icons/md';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { LuNewspaper } from "react-icons/lu";
const Sidebar = () => {
  let isTabletMid = useMediaQuery({ query: '(max-width: 767px)' });
  const [open, setOpen] = useState(isTabletMid ? false : true);
  const sidebarRef = useRef();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(userLogoutOp());
    localStorage.removeItem('userAuthToken');
    localStorage.removeItem('locationIp');
    navigate('/seva-login');
  };

  useEffect(() => {
    if (isTabletMid) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isTabletMid]);

  useEffect(() => {
    isTabletMid && setOpen(false);
  }, [pathname]);

  const Nav_animation = isTabletMid
    ? {
        open: {
          x: 0,
          width: '16rem',
          transition: {
            damping: 40,
          },
        },
        closed: {
          x: -250,
          width: 0,
          transition: {
            damping: 40,
            delay: 0.15,
          },
        },
      }
    : {
        open: {
          width: '16rem',
          transition: {
            damping: 40,
          },
        },
        closed: {
          width: '4rem',
          transition: {
            damping: 40,
          },
        },
      };

  return (
    <div>
      <div
        onClick={() => setOpen(false)}
        className={`md:hidden fixed inset-0 max-h-screen z-[998] bg-black/50 ${
          open ? 'block' : 'hidden'
        } `}
      ></div>
      <motion.div
        ref={sidebarRef}
        variants={Nav_animation}
        initial={{ x: isTabletMid ? -250 : 0 }}
        animate={open ? 'open' : 'closed'}
        className="bg-[#033d62] text-white shadow-xl z-[999] max-w-[16rem] w-[16rem] overflow-hidden md:relative fixed h-screen"
      >
        <div className="flex items-center gap-2.5 font-medium border-b py-3 border-slate-300 mx-3">
          {open && (
            <span className="font-playwrite">
              <img
                src={`${process.env.REACT_APP_S3_URL}/logo.png`}
                alt="logo"
                className="md:h-[85px] md:w-[130px] h-[70px] w-[80px] border-gray-200 p-2 rounded-tr-3xl rounded-bl-3xl"
              />
            </span>
          )}
        </div>
        <div className="flex flex-col h-full">
          <ul className="whitespace-pre px-2.5 text-[0.9rem] py-5 flex flex-col gap-1  font-medium overflow-x-hidden   md:h-[68%] h-[70%]">
            <li>
              <NavLink to={'/dashboard'} className="linkNav">
                <AiOutlineAppstore size={23} className="min-w-max" />
                Dashboard
              </NavLink>
            </li>
            
            <li>
              <NavLink to={'/gms'} className="linkNav">
                <FaTasks size={23} className="min-w-max" />
                Grievience Management
              </NavLink>
            </li>
            <li>
              <NavLink to={'/budget'} className="linkNav">
                <CiDollar size={23} className="min-w-max" />
                Budget
              </NavLink>
            </li>
            <li>
              <NavLink to={'/calendar'} className="linkNav">
                <FaRegCalendarDays size={23} className="min-w-max" />
                Calendar
              </NavLink>
            </li>
            <li>
              <NavLink to={'/news'} className="linkNav">
                <LuNewspaper size={23} className="min-w-max" />
                News
              </NavLink>
            </li>
          </ul>
          {open && (
            <div className="flex-1 text-sm z-50  max-h-48 my-auto whitespace-pre w-full font-medium ">
              <div className="flex border-y border-slate-300 p-4 items-center justify-between">
                <div className="linkNav">
                  <AiOutlineLogout size={23} />
                  <button onClick={handleLogout}>Logout</button>
                </div>
              </div>
            </div>
          )}
        </div>
        <motion.div
          onClick={() => {
            setOpen(!open);
          }}
          animate={
            open
              ? {
                  x: 0,
                  y: 0,
                  rotate: 0,
                }
              : {
                  x: -10,
                  y: -200,
                  rotate: 180,
                }
          }
          transition={{ duration: 0 }}
          className="absolute w-fit h-fit md:block z-50 hidden right-2 bottom-0 cursor-pointer"
        >
          <IoIosArrowBack size={25} />
        </motion.div>
      </motion.div>
      <div
        className="p-2 bg-[#033d62] h-screen md:hidden"
        onClick={() => setOpen(true)}
      >
        <MdMenu size={35} className="text-white" />
      </div>
    </div>
  );
};

export default Sidebar;
