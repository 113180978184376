import { useState } from 'react';
import { MasonryPhotoAlbum } from 'react-photo-album';
import 'react-photo-album/masonry.css';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

const BannerCarousel = ({
  useKeyboardArrows,
  showThumbs,
  autoPlay,
  stopOnHover,
  swipeable,
  infiniteLoop,
  emulateTouch,
  showStatus,
  id,
}) => {
  const [index, setIndex] = useState(-1);
  const photos = [
    {
      src: 'https://taskresultspro.s3.amazonaws.com/slide1.png',
      width: 800,
      height: 600,
    },
    {
      src: 'https://hbt-seva.s3.ap-south-1.amazonaws.com/img9.png',
      width: 800,
      height: 600,
    },
    {
      src: 'https://taskresultspro.s3.amazonaws.com/slide3.png',
      width: 800,
      height: 600,
    },
    {
      src: 'https://taskresultspro.s3.amazonaws.com/slide4.png',
      width: 800,
      height: 600,
    },
    {
      src: 'https://taskresultspro.s3.amazonaws.com/slide5.png',
      width: 800,
      height: 600,
    },
    {
      src: 'https://taskresultspro.s3.amazonaws.com/slide6.png',
      width: 800,
      height: 600,
    },
    {
      src: 'https://hbt-seva.s3.ap-south-1.amazonaws.com/Ram_Mohan_Naidu_.avif',
      width: 800,
      height: 600,
    },
    {
      src: 'https://taskresultspro.s3.amazonaws.com/slide8.png',
      width: 800,
      height: 600,
    },
    {
      src: 'https://taskresultspro.s3.amazonaws.com/slide9.png',
      width: 800,
      height: 600,
    },
    {
      src: 'https://hbt-seva.s3.ap-south-1.amazonaws.com/ram-mohan-1717907977.jpg',
      width: 800,
      height: 600,
    },

    {
      src:'https://hbt-seva.s3.ap-south-1.amazonaws.com/img1.png',
      width: 800,
      height: 600,
    },
    {
      src: 'https://hbt-seva.s3.ap-south-1.amazonaws.com/img2.png',
      width: 800,
      height: 600,
    },
    {
      src: 'https://hbt-seva.s3.ap-south-1.amazonaws.com/img7.png',
      width: 800,
      height: 600,
    },
    {
      src: 'https://hbt-seva.s3.ap-south-1.amazonaws.com/img4.png',
      width: 800,
      height: 600,
    },
    {
      src: 'https://hbt-seva.s3.ap-south-1.amazonaws.com/img8.png',
      width: 800,
      height: 600,
    },

  ];
  return (
    // <Carousel
    //   useKeyboardArrows={useKeyboardArrows}
    //   showThumbs={showThumbs}
    //   autoPlay={autoPlay}
    //   stopOnHover={stopOnHover}
    //   swipeable={swipeable}
    //   infiniteLoop={infiniteLoop}
    //   emulateTouch={emulateTouch}
    //   showStatus={showStatus}
    // >
    //   {images.map((URL, index) => (
    //     <div className="slide h-1/4">
    //       <div className="relative">
    //         <img
    //           alt="prms_gallery"
    //           className="object-fill h-96 lg:h-[500px] rounded-lg"
    //           src={URL}
    //           key={index}
    //         />
    //         <span className="flex flex-row justify-between">
    //           <Link
    //             to={'https://x.com/rammnk'}
    //             target="_blank"
    //             rel="noopener noreferrer"
    //           >
    //             <FaXTwitter
    //               className="absolute top-4 right-8 bg-white"
    //               size="24"
    //             />
    //           </Link>
    //           <Link
    //             to={'https://www.facebook.com/RamMNK'}
    //             target="_blank"
    //             rel="noopener noreferrer"
    //           >
    //             <FaSquareFacebook
    //               className="absolute top-4 right-16 bg-white"
    //               size="24"
    //             />
    //           </Link>
    //           <Link
    //             to={'https://www.instagram.com/rammnk'}
    //             target="_blank"
    //             rel="noopener noreferrer"
    //           >
    //             <FaSquareInstagram
    //               className="absolute top-4 right-24 bg-white"
    //               size="24"
    //             />
    //           </Link>
    //         </span>
    //       </div>
    //     </div>
    //   ))}
    // </Carousel>
    <div id={id} className="pl-2 pr-2">
      <MasonryPhotoAlbum
        photos={photos}
        targetRowHeight={150}
        onClick={({ index }) => setIndex(index)}
      />
      <Lightbox
        slides={photos}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
    </div>
  );
};
export default BannerCarousel;



