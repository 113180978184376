import React from "react";
import { FaEdit, FaList, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import moment from "moment";

function TableCard({
  onEdit,
  onView,
  onDelete,
  status,
  key,
  ticketNo,
  applicantName,
  requestDescription,
  requestType,
  issueOpen,
  ticketCreatedBy,
  requestNumber,
  requestCreatedBy,
  requestedAmount,
  requestedDate,
  grantedAmount,
  grantedDate,
  newsId,
  title,
  description,
  link,
}) {
  return (
    <div
      key={key}
      className="block rounded-2xl flex items-center justify-between mt-2 mb-2 bg-white p-6 shadow-lg border border-gray-200"
    >
      <div className="space-y-2">
        {ticketNo && (
          <p
            className="text-lg font-semibold text-blue-900 underline cursor-pointer"
            onClick={onView}
          >
            <span className="font-bold">Ticket Number:</span> {ticketNo}
          </p>
        )}
        {requestNumber && (
          <p
            className="text-lg font-semibold text-blue-900 underline cursor-pointer"
            onClick={onView}
          >
            <span className="font-bold">Request Number:</span> {requestNumber}
          </p>
        )}

        {requestCreatedBy && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Requested Createdby:</span>{" "}
            {requestCreatedBy}
          </p>
        )}
        {requestedAmount && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Requested Amount:</span>{" "}
            {requestedAmount}
          </p>
        )}
        {requestedDate && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Requested Date:</span> {requestedDate}
          </p>
        )}
        {grantedAmount && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Granted Amount:</span> {grantedAmount}
          </p>
        )}
        {grantedDate && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Granted Date:</span> {grantedDate}
          </p>
        )}



      {/* news */}
      {newsId && (
          <p
            className="text-lg font-semibold text-blue-900 underline cursor-pointer"
            onClick={onView}
          >
            <span className="font-bold">News Id:</span> {newsId}
          </p>
        )}
        {title && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Title:</span> {title}
          </p>
        )}
        {description && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Description:</span> {description}
          </p>
        )}
        {link && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Link:</span> {link}
          </p>
        )}

        {status && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Status:</span> {status}
          </p>
        )}

        {applicantName && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Applicant Name:</span> {applicantName}
          </p>
        )}

        {requestDescription && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Request Description:</span>{" "}
            {requestDescription}
          </p>
        )}
        {requestType && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Request Type:</span> {requestType}
          </p>
        )}
        {issueOpen && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Issue Open Date:</span> {issueOpen}
          </p>
        )}
        {ticketCreatedBy && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Ticket Created By:</span>{" "}
            {ticketCreatedBy}
          </p>
        )}
      </div>
      <div className="flex justify-end  space-x-4">
        {onEdit && (
          <div
            onClick={onEdit}
            className="cursor-pointer text-gray-600 hover:text-blue-600 transition"
          >
            <FaEdit size={25} />
          </div>
        )}
        {onView && (
          <div
            onClick={onView}
            className="cursor-pointer text-gray-600 hover:text-blue-600 transition"
          >
            <FaEye size={25} />
          </div>
        )}
        {onDelete && (
          <div
            onClick={onDelete}
            className="cursor-pointer text-gray-600 hover:text-blue-600 transition"
          >
            <MdDelete size={25} />
          </div>
        )}
        {/* {viewNotes && (
          <div onClick={viewNotes} className="cursor-pointer text-gray-600 hover:text-blue-600 transition">
            <FaList size={20} />
          </div>
        )} */}
      </div>
    </div>
  );
}

export default TableCard;
