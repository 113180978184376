import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { removeUser } from "../../redux/actions/login.action";
import { userLogoutOp } from "../../redux/operations/user.operations";
import { useDispatch } from "react-redux";
import { Offcanvas, Ripple, Dropdown, initTWE } from "tw-elements";
import { Button } from "@mui/material";
import {
  FaSquareXTwitter,
  FaSquareFacebook,
  FaSquareInstagram,
  FaSquareYoutube,
} from "react-icons/fa6";

function Navbar() {
  initTWE({ Offcanvas, Ripple, Dropdown });
  const [show, setShow] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  // Conditionally set background class
  const navbarBgClass =
    location.pathname === "/" ? "bg-[#ff7900]" : "bg-[#ff7900] shadow-md";

  useEffect(() => {
    if (localStorage.getItem("userAuthToken")) {
      setIsLogin(true);
    }
  }, [params]);

  const onClickDropDown = () => {
    setShow(!show);
  };

  const mobileMenu = () => {
    setShowMobileNav(!showMobileNav);
  };

  const handleLogout = () => {
    localStorage.removeItem("userAuthToken");
    localStorage.removeItem("locationIp");
    dispatch(userLogoutOp());
    navigate("/seva-login");
  };
  const goTo = (link) => {
    const releventDiv = document.getElementById(link);
    if (releventDiv) {
      releventDiv.scrollIntoView({ behavior: "smooth" });
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <nav
        className={`${navbarBgClass} border-gray-200 lg:px-4 sm:px-2 py-2.5`}
      >
        <div className="container flex flex-wrap items-center justify-between mx-auto">
          <div className="flex flex-row justify-between">
            <Link
              to="/"
              className="flex items-center md:pl-0"
              onClick={() => goTo("home")}
            >
              {/* <span className="self-center text-2xl  whitespace-nowrap dark:text-white font-manrope"> */}
              {/* <img
                src={`${process.env.REACT_APP_S3_URL}/logo.png`}
                alt="logo"
                className="md:h-[85px] md:w-[130px] h-[70px] w-[80px] border-gray-200 p-2 rounded-tr-3xl rounded-bl-3xl"
              /> */}
              {/* </span> */}
              <div className="flex-1">
                <h1 className="text-4xl font-bold italic text-white font-heading">
                  Rammohan Naidu
                </h1>
              </div>
            </Link>
          </div>

          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm text-white rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:bg-blueBg"
            aria-controls="navbar-default"
            aria-expanded="false"
            onClick={mobileMenu}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
          {/*Mobile Nav*/}
          {showMobileNav ? (
            <div className="w-full md:hidden" id="navbar-default">
              <ul className="flex flex-col p-4 mt-4 rounded-lgmd:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0">
                {isLogin ? (
                  <>
                    <li>
                      <Link
                        to="/"
                        className="block py-2 pl-3 pr-4 text-gray-50  text-md rounded md:border-0 md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                        onClick={() => goTo("news")}
                      >
                        Latest News
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="https://seva.rammnk.com/"
                        target="__blank"
                        className="block py-2 pl-3 pr-4 text-gray-50 text-md rounded md:border-0 md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                      >
                        SEVA
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/gallery"
                        className="block py-2 pl-3 pr-4 text-gray-50 text-md rounded md:border-0 md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                        // onClick={() => goTo("gallery")}
                      >
                        Gallery
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/about"
                        className="block py-2 pl-3 pr-4 text-gray-50 text-md rounded md:border-0 md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                        // onClick={() => goTo("about")}
                      >
                        About
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/team"
                        className="block py-2 pl-3 pr-4 text-gray-50 text-md rounded md:border-0 md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                      >
                        AMO
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/scorecard"
                        className="block py-2 pl-3 pr-4 text-gray-50 text-md rounded md:border-0 md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                      >
                        Scorecard
                      </Link>
                    </li>

                    {/* <li className="mt-2 mb-2">
                      <Button
                        onClick={handleLogout}
                        color="error"
                        variant="contained"
                        // className="py-2 pl-3 pr-4 border-2 h-7 flex justify-center items-center w-14 border-danger text-white bg-danger-700 rounded hover:bg-gray-100 md:hover:bg-danger-700 md:hover:text-white md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                      >
                        Logout
                      </Button>
                    </li> */}
                  </>
                ) : (
                  <>
                    <li>
                      <Link
                        to="/"
                        className="block py-2 pl-3 pr-4 text-gray-50 text-md rounded md:border-0 md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                        onClick={() => goTo("news")}
                      >
                        Latest News
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="https://seva.rammnk.com/"
                         target="__blank"
                        className="block py-2 pl-3 pr-4 text-gray-50 text-md rounded md:border-0 md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                      >
                        SEVA
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/gallery"
                        className="block py-2 pl-3 pr-4 text-gray-50 text-md rounded md:border-0 md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                        // onClick={() => goTo("gallery")}
                      >
                        Gallery
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/about"
                        className="block py-2 pl-3 pr-4 text-gray-50 text-md rounded md:border-0 md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                        // onClick={() => goTo("about")}
                      >
                        About
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/team"
                        className="block py-2 pl-3 pr-4 text-gray-50 text-md rounded md:border-0 md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                      >
                        AMO
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/scorecard"
                        className="block py-2 pl-3 pr-4 text-gray-50 text-md rounded md:border-0 md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                      >
                        Scorecard
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
          ) : null}
          {/*Mobile Nav*/}
          <div className="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 text-white">
              {isLogin ? (
                <>
                  <li>
                    <Link
                      to="/"
                      className="block py-2 pl-3 mt-2 pr-4 text-gray-50 text-md rounded md:border-0 md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                      onClick={() => goTo("news")}
                    >
                      Latest News
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="https://seva.rammnk.com/"
                       target="__blank"
                      className="block py-2 pl-3 mt-2 pr-4 text-gray-50 text-md rounded md:border-0 md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                    >
                      SEVA
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/gallery"
                      className="block py-2 pl-3 pr-4 mt-2 text-gray-50 text-md rounded md:border-0 md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                      // onClick={() => goTo("gallery")}
                    >
                      Gallery
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about"
                      className="block py-2 pl-3 pr-4 mt-2 text-gray-50 text-md rounded md:border-0 md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                      // onClick={() => goTo("about")}
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/team"
                      className="block py-2 pl-3 pr-4 mt-2 text-gray-50 text-md rounded md:border-0 md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                    >
                      AMO
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/scorecard"
                      className="block py-2 pl-3 pr-4 mt-2 text-gray-50 text-md rounded md:border-0 md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                    >
                      Scorecard
                    </Link>
                  </li>

                  {/* <li>
                    <Button
                      onClick={handleLogout}
                      color="error"
                      variant="contained"
                      // className="py-2 pl-3 pr-4 border-2 h-7 flex justify-center items-center w-14 border-danger text-white bg-danger-700 rounded hover:bg-gray-100 md:hover:bg-danger-700 md:hover:text-white md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                    >
                      Logout
                    </Button>
                  </li> */}
                </>
              ) : (
                <>

                  <li>
                    <Link
                      to="/"
                      className="block py-2 pl-3 pr-4 text-gray-50 text-md rounded md:border-0 md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                      onClick={() => goTo("news")}
                    >
                      Latest News
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="https://seva.rammnk.com/"
                       target="__blank"
                      className="block py-2 pl-3 pr-4 text-gray-50 text-md rounded md:border-0 md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                    >
                      SEVA
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/gallery"
                      className="block py-2 pl-3 pr-4 text-gray-50 text-md rounded md:border-0 md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                      // onClick={() => goTo("gallery")}
                    >
                      Gallery
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about"
                      className="block py-2 pl-3 pr-4 text-gray-50 text-md rounded md:border-0 md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                      // onClick={() => goTo("about")}
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/team"
                      className="block py-2 pl-3 pr-4 text-gray-50 text-md rounded md:border-0 md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                    >
                      AMO
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/scorecard"
                      className="block py-2 pl-3 pr-4 text-gray-50 text-md rounded md:border-0 md:p-0 font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                    >
                      Scorecard
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
