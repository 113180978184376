import React from "react";
import Navbar from "../Navbar/navbar";


const HeroSection = () => {
  return (
    <div className="w-full relative">
      {/* Navbar */}
      {/* <div className="absolute top-0 left-0 w-full z-10">
        <Navbar />
      </div> */}

      {/* Hero Section */}
      <div className="relative">
        {/* Tricolour Background Image */}
        <img
          src='https://hbt-seva.s3.ap-south-1.amazonaws.com/tricolour.jpg'
          alt="Tricolour"
          className="w-full h-screen object-cover"
        />

        {/* Text Content Overlay */}
        <div className="absolute bottom-40 inset-0 flex flex-col items-start justify-center text-center pt-10 px-4 md:px-20">
          <h1 className="text-white text-4xl sm:text-5xl md:text-6xl font-heading font-extrabold mb-8 drop-shadow-md">
            Welcome to my official website
          </h1>
          <p className="text-white text-start text-lg sm:text-xl md:text-2xl font-paragraph font-bold leading-relaxed mb-6 drop-shadow-sm">
            — a platform dedicated to open dialogue, transparency, and <br />
            service to you, the people of India.
          </p>
        </div>

        {/* Ram MNK Image */}
        <div className="absolute bottom-0 right-0 lg:right-40 w-full flex justify-end items-end px-4 sm:px-10 md:px-20">
          <img
            src='https://hbt-seva.s3.ap-south-1.amazonaws.com/ramMNK.png'
            alt="Ram MNK"
            className="w-56 sm:w-64 md:w-80 lg:w-96 object-contain"
          />
        </div>
      </div>
     </div>
  );
};

export default HeroSection;
