export const newsFields = [
  {
    fieldName: "newsId",
    fieldTitle: "News Id",
    validationObj: {
      required: "Please Enter News Id",
    },

    fieldType: "text",
  },
  {
    fieldName: "title",
    fieldTitle: "Title",
    validationObj: {
      required: "Please Enter Title",
    },
    fieldType: "text",
  },

  {
    fieldName: "link",
    fieldTitle: "Link",
    validationObj: {
      required: "Please Enter Link",
    },
    fieldType: "text",
  },
  {
    fieldName: "description",
    fieldTitle: "Description",
    validationObj: {
      required: "Please Enter Description",
    },
    fieldType: "textarea",
  },
  {
    fieldName: "imageUpload",
    fieldTitle: "Upload Image",
    fieldType: "upload",
  },
];

export const newsTableHeader = [
  {
    id: "newsId",
    numeric: false,
    label: "News Id",
    disabled: true,
  },

  // {
  //   id: 'news',
  //   numeric: false,
  //   label: 'News',
  //   disabled: true,
  // },
  {
    id: "title",
    numeric: false,
    label: "Title",
    disabled: true,
  },
  {
    id: "description",
    numeric: false,
    label: "Description",
    disabled: true,
  },
  {
    id: "link",
    numeric: false,
    label: "Link",
    disabled: true,
  },
  {
    id: "status",
    numeric: false,
    label: "Status",
    disabled: true,
  },
  {
    id: "action",
    numeric: false,
    label: "Actions",
    disabled: true,
  },
];
