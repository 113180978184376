import React, { useEffect } from "react";
import { Timeline } from "react-twitter-widgets";
import {
  XEmbed,
  InstagramEmbed,
  FacebookEmbed,
} from "react-social-media-embed";

const Feed = () => {
  return (
    <section className="bg-gray-100 py-12">
      <div className="container mx-auto px-4">
        {/* Header Section */}
        {/* <div className="text-center mb-10">
          <h2 className="text-2xl font-semibold text-gray-800 uppercase">Social Feeds</h2>
          <p className="text-gray-600 mt-2">Stay updated with our latest social media updates</p>
        </div> */}
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold font-heading text-gray-800 mb-4">
            Social Feeds
          </h2>
          <p className="text-gray-700 font-paragraph text-lg">
            Stay updated with our latest social media updates.
          </p>
        </div>

        {/* Grid Section */}
        {/* <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 h-auto "> */}
        <div className="flex justify-center">
          {/* Tweet Feed */}
          <div className="bg-white shadow-lg rounded-lg p-6">
            <div className="flex justify-center mb-4">
              {/* <h5 className="text-lg font-semibold text-yellow-700 uppercase border-b-2 border-yellow-700 pb-2">
                Tweeter Feed
              </h5> */}
              <h2 className="text-2xl font-bold font-heading text-gray-800 mb-4">
                Tweeter Feed
              </h2>
            </div>
            <div className="flex justify-center">
              {/* <XEmbed
                url="https://twitter.com/PixelAndBracket/status/1356633038717923333"
                width="100%"
              /> */}
              <Timeline
                dataSource={{ sourceType: "profile", screenName: "rammnk" }}
                options={{ width: "380", height: "600" }}
              />
            </div>
          </div>

          {/* Facebook Feed */}
          {/* <div className="bg-white shadow-lg rounded-lg p-6">
            <div className="flex justify-center mb-4">
              
              <h2 className="text-2xl font-bold font-heading text-gray-800 mb-4">
              Facebook Feed
              </h2>
            </div>
            <div className="flex justify-center">
              <FacebookEmbed
                url="https://www.facebook.com/andrewismusic/posts/451971596293956"
                width="100%"
              />
            </div>
          </div> */}

          {/* Instagram Feed */}
          {/* <div className="bg-white shadow-lg rounded-lg p-6">
            <div className="flex justify-center mb-4">
              <h2 className="text-2xl font-bold font-heading text-gray-800 mb-4">
              Instagram Feed
              </h2>
            </div>
            <div className="flex justify-center">
              <InstagramEmbed
                url="https://www.instagram.com/p/CUbHfhpswxt/"
                width="100%"
                captioned
              />
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Feed;
