import BannerCarousel from "../BannerCarousel/BannerCarousel";
import Footer from "../Footer";
import SecondFooter from "../Footer/SecondFooter";
import Navbar from "../Navbar/navbar";

const Gallery = () => {
  return (
    <>
      <Navbar />
      <section id="gallery" className="bg-gray-50 mb-3">
        <div className="container mx-auto">
          {/* Header Section */}
          <div className="text-center mt-12 mb-12">
            <h2 className="text-4xl font-bold font-heading text-gray-800 mb-4">
              Photo Gallery
            </h2>
            <p className="text-gray-700 font-paragraph text-lg">
              Explore our photo gallery to relive cherished memories and capture
              moments that inspire. <br />
              Each photo tells a unique story—join us in celebrating creativity
              and connection.
            </p>
          </div>
          <BannerCarousel />
        </div>
      </section>
      <SecondFooter />
    </>
  );
};
export default Gallery;
