import { useEffect, useState } from "react";
import { get } from "lodash";
import { Controller, useForm } from "react-hook-form";
import InputField from "../../../Common/InputField";
import CheckboxField from "../../../Common/CheckboxField";
import UploadField from "../../../Common/UploadField";
import { Grid, Button } from "@mui/material";
import TicketCharter from "../../Ticket-Notes/TicketCharter";
import DateField from "../../../Common/DateField";

import {
  postRequest,
  putRequest,
  getRequest,
} from "../../interceptor/interceptor";
import FullScreenLoader from "../../Loader/FullScreenLoader";
import {
  UPDATE_BUDGET_NEWS,
  ADD_BUDGET_NEWS,
  UNIQUE_ID,
  DOCUMENT_UPLOAD,
} from "../../../apiConst";
import { useLocation, useNavigate } from "react-router";
import SelectDropdown from "../../../Common/SelectDropdown";
import { newsFields } from "../../../Fields/newsFields";
import CkEditorComponent from "../../../Common/CkEditor";
import moment from "moment";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { toast } from "react-toastify";

const AddNews = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const fields = [...newsFields];
  const [id, setId] = useState(null);
  const [newsId, setNewsId] = useState(
    location?.state?.data?.task?.newsId || null
  );
  const [isEdit, setIsEdit] = useState(null);

  const [isTicketToggle, setIsTicketToggle] = useState(true); // State to toggle the accordion
  const [image, setImage] = useState('');
  const ticketToggleAccordion = () => {
    setIsTicketToggle(!isTicketToggle);
  };

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    control,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    if (location?.state?.isEdit || location?.state?.isView) {
      location?.state?.isEdit && setIsEdit(location?.state?.isEdit);
      const { task } = location?.state?.data;
      console.log("task", task);
      // Set form fields using setValue
      fields.map((field) => setValue(field.fieldName, task[field.fieldName]));
    } else {
      // If neither edit nor view, generate unique ID or perform other logic
      getUniqueId();
    }
  }, []);

  const onCancel = () => {
    navigate("/news", {});
  };
  const getUniqueId = () => {
    setIsLoading(true);
    getRequest(`${UNIQUE_ID}${"NW"}`)
      .then((res) => {
        console.log("res", res);
        const { data } = res;
        if (data) {
          setValue("newsId", data);
          setNewsId(data);
          setIsLoading(false);
        } else {
          setIsLoading(true);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const onSubmit = (data, e) => {
    console.log("e", e.target.value);
    console.log("data in news", data);
    let url = "";
    setIsLoading(true);
    const payload = {
      newsId:data?.newsId,
      title: data?.title,
      description: data?.description,
      link: data?.link,
      module: "news",
      image:image
    };
  
    if (isEdit) {
      url = putRequest(`${UPDATE_BUDGET_NEWS}`, payload);
    } else {
      url = postRequest(`${ADD_BUDGET_NEWS}`, payload);
    }
    url
      .then((res) => {
        console.log("res", res);
        toast.info(res);
        setIsLoading(false);
        navigate("/news");
      })
      .catch(() => {
        setIsLoading(false);
      });
    setIsLoading(false);
  };
  const onUpload = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const file = e.target.files[0];
    const formData = new FormData();

    formData.append("file", file);
    setValue("uploadFile", "");
    setValue("uploadFileName", "");

    postRequest(`${DOCUMENT_UPLOAD}`, formData, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        const { url, fileName } = res.data.data[0];
        setValue("uploadFile", url);
        setValue("uploadFileName", fileName);
        setImage(url)
        setTimeout(() => {
          setIsLoading(false);
        }, 100);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const onRemoveFile = (e) => {
    setValue("uploadFile", "");
    setValue("uploadFileName", "");
    setImage('')
  };
  return (
    <>
      {isLoading && <FullScreenLoader />}
      <div className="block rounded-lg bg-white p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] m-4 overflow-auto">
        <div className="flex mb-2 text-xl font-extrabold text-primary-600">
          {isEdit ? "Edit" : location?.state?.isView ? "View" : "Add"} News
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div
                className="flex justify-between items-center text-white rounded-md bg-gray-700 p-2 mb-1 cursor-pointer"
                onClick={ticketToggleAccordion} // Toggle on click
              >
                <h2>News Details</h2>
                {/* Render the icon based on the toggle state */}
                {isTicketToggle ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </Grid>
            {isTicketToggle &&
              newsFields?.map((field) => {
                const {
                  fieldTitle,
                  fieldName,
                  fieldType,
                  validationObj,
                  options,
                } = field;

                return (
                  <>
                    <Grid item xs={12} lg={fieldType==='textarea'?12:4} key={fieldName}>
                      {fieldType === "upload" ? (
                        <div className="">
                          <label className="block text-gray-800 text-sm font-bold mb-3 pr-4">
                            {fieldTitle}
                          </label>
                          <UploadField
                            fieldName={fieldName}
                            validationObj={validationObj}
                            register={register}
                            errors={errors}
                            fieldType={fieldType}
                            onChange={onUpload}
                            onRemoveFile={onRemoveFile}
                            accept="image/*"
                            valueClassName={"flex m-2 items-center"}
                            // value={
                            //   getValues('uploadFileName') !== ''
                            //     ? getValues('uploadFileName')
                            //     : ''
                            // }
                            // onBlur={onFieldBlur}
                            placeholder={fieldTitle}
                          />
                        </div>
                      ) : (
                        <>
                          <label className="block text-gray-800 text-sm font-bold mb-3 pr-4">
                            {fieldTitle}
                          </label>
                          <InputField
                            fieldName={fieldName}
                            validationObj={validationObj}
                            register={register}
                            disabled={
                              fieldName === "newsId"
                                ? true
                                : location?.state?.isView
                            }
                            errors={errors}
                            fieldType={fieldType}
                          />
                        </>
                      )}
                    </Grid>
                  </>
                );
              })}
            {image!==''&& (
              <div className="mt-4">
                <img
                  src={image}
                  alt="Uploaded file preview"
                  className="w-32 h-32 object-cover rounded-md border border-gray-300"
                />
                <Button
                  variant="contained"
                  color="error"
                  className="mt-2"
                  onClick={onRemoveFile}
                >
                  Remove Image
                </Button>
              </div>
            )}
          </Grid>
          <TicketCharter
            taskId={id}
            ticketNo={newsId}
            isView={location?.state?.isView}
            noteStatus="inprogress"
          />

          <div className="flex flex-col lg:flex-row items-center justify-center space-x-4">
            <Grid item>
              <Button
                variant="contained"
                color="error"
                onClick={onCancel}
                className="mt-5"
              >
                {location?.state?.isView ? "Back" : "Cancel"}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                className="mt-5"
                disabled={location?.state?.isView}
                value="save"
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="mt-5"
                disabled={location?.state?.isView}
                value="submit"
                onClick={handleSubmit(onSubmit)}
              >
                {isEdit ? "Update" : "Save & Submit"}
              </Button>
            </Grid>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddNews;
