export const budgetPersonFields = [
    {
      fieldName: 'requestNumber',
      fieldTitle: 'Request Number',
      validationObj: {
        required: 'Please Enter Request Number',
      },
  
      fieldType: 'text',
    },
    {
      fieldName: 'requestCreatedBy',
      fieldTitle: 'Request Created by',
      fieldType: 'text', // Could be 'disabledInput' if auto-populated
      // readOnly: true, // Potentially make it read-only
    },
  
    {
      fieldName: 'designation',
      fieldTitle: 'Designation',
      fieldType: 'text', // Could be 'disabledInput' if auto-populated
      readOnly: true,
    },
    // {
    //   fieldName: 'issueOpen',
    //   fieldTitle: 'Issue Open Date',
    //   fieldType: 'date',
    // },
  
    // {
    //   fieldName: 'targetResolution',
    //   fieldTitle: 'Target Resolution',
    //   fieldType: 'date',
    // },
  ];

  export const budgetRequestFields = [
    {
      fieldName: 'requestedAmount',
      fieldTitle: 'Requested Amount',
      validationObj: {
        required: 'Please Enter Requested Amount',
      },
  
      fieldType: 'text',
    },
    

    
    {
      fieldName: 'requestedDate',
      fieldTitle: 'Requested Date',
      fieldType: 'date',
    },
    {
      fieldName: 'grantedAmount',
      fieldTitle: 'Granted Amount',
      validationObj: {
        required: 'Please Enter Applicant Name',
      },
  
      fieldType: 'text',
    },
  
    {
      fieldName: 'grantedDate',
      fieldTitle: 'Granted Date',
      fieldType: 'date',
    },
  ];



  export const budgetTableHeader = [
    // {
    //   id: 'sno',
    //   numeric: false,
    //   label: 'S.No',
    //   disabled: true,
    // },
    {
      id: 'requestNumber',
      numeric: false,
      label: 'Request Number',
      disabled: true,
    },
    {
      id: 'requestCreatedBy',
      numeric: false,
      label: 'CreatedBy',
      disabled: true,
    },
    {
      id: 'designation',
      numeric: false,
      label: 'Designation',
      disabled: true,
    },
    {
      id: 'requestedAmount',
      numeric: false,
      label: 'Requested Amount',
      disabled: true,
    },
    {
      id: 'requestedDate',
      numeric: false,
      label: 'Requested Date',
      disabled: true,
    },
    {
      id: 'grantedAmount',
      numeric: false,
      label: 'Granted Amount',
      disabled: true,
    },
  
    {
      id: 'grantedDate',
      numeric: false,
      label: 'Granted Date',
      disabled: true,
    },
    {
      id: 'status',
      numeric: false,
      label: 'Status',
      disabled: true,
    },
    {
      id: 'action',
      numeric: false,
      label: 'Actions',
      disabled: true,
    },
  ];