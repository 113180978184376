import React from "react";

const TransformSection = () => {
  return (
    <div id="about" className="flex flex-col lg:flex-row items-center lg:items-start justify-between bg-white p-8 gap-8">
  {/* Left Image Section */}
  <div className="lg:w-1/2 w-full flex justify-center">
    <img
      src="https://hbt-seva.s3.ap-south-1.amazonaws.com/img6.png"
      alt="Speaker"
      className="h-auto max-w-full rounded-lg"
    />
  </div>

  {/* Right Content Section */}
  <div className="lg:w-1/2 w-full text-center lg:text-left space-y-4">
  <h1 className="text-4xl font-heading font-bold text-gray-800 leading-tight mb-2">
    Transforming Aviation for a <br className="hidden md:block" /> Better Tomorrow
  </h1>
  <p className="text-gray-700 text-lg font-paragraph">
    Under the visionary leadership of Ram Mohan Naidu, the aviation sector is
    soaring to new heights. We are committed to modernizing infrastructure,
    enhancing connectivity, and ensuring a world-class experience for every
    citizen.
  </p>

  <div className="space-y-2">
    <div>
      <h2 className="text-2xl font-bold text-gray-800 font-heading mb-1">Expanding Connectivity</h2>
      <p className="text-gray-700 text-lg font-paragraph">
        With a focus on regional airports and better accessibility, we are
        connecting India like never before. Our initiatives aim to empower
        people and bridge distances, fostering economic growth and social
        inclusion.
      </p>
    </div>

    <div>
      <h2 className="text-2xl font-bold text-gray-800 font-heading mb-2">Driving Innovation in Aviation</h2>
      <p className="text-gray-700 text-lg font-paragraph">
        From green airports to cutting-edge technology, we are reshaping the
        future of aviation. Our policies prioritize sustainability, efficiency,
        and passenger comfort, making India's aviation sector a global leader.
      </p>
    </div>
  </div>
</div>


    {/* Button Section */}
    {/* <div>
      <button className="bg-blue-600 text-white font-bold py-2 px-6 rounded hover:bg-blue-700 transition duration-300">
        Manifesto
      </button>
    </div> */}
  </div>


  );
};
export default TransformSection;