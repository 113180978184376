import { get } from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  LIST_BUDGET_NEWS,
} from "../../apiConst";
import SelectDropdown from "../../Common/SelectDropdown";
import { taskStatusOptions } from "../../utils";
import { postRequest, putRequest } from "../interceptor/interceptor";
import FullScreenLoader from "../Loader/FullScreenLoader";
import { FaListUl, FaThLarge, FaTasks } from "react-icons/fa";
import { VscTasklist } from "react-icons/vsc";
import BudgetList from "./BudgetList";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { IoMdAdd, IoMdSend } from "react-icons/io";
import { resetClipboardStubOnView } from "@testing-library/user-event/dist/cjs/utils/index.js";

export default function Budget() {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(rowsPerPage);
  const [totalGrantedAmount, setTotalGrantedAmount] = useState(0);
  const [totalRequestedAmount, setTotalRequestedAmount] = useState(0);
  const [tableRowsData, setTableRowsData] = useState([]);
  const navigate = useNavigate();

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    fetchListData();
  }, []);
  const fetchListData = (pg = 1, size = 10, search = "", searchKey = null) => {
    setIsLoading(true);

    let payload = {
      filter: {},
      sortOrder: -1,
      page: pg,
      pageSize: size,
      module:'budget'
    };

    const { searchField } = getValues();
    const key = searchKey ? searchKey : searchField;
    if (key && search) {
      payload.filter[key] = search;
    }
    postRequest(LIST_BUDGET_NEWS, payload)
      .then((res) => {
        console.log("res", res);
        const { result, totalCount,totalGrantedAmount,totalRequestedAmount } = res.data.data;
        setTableRowsData(result || []);
        setTotalGrantedAmount(totalGrantedAmount)
        setTotalRequestedAmount(totalRequestedAmount)
        setTotalCount(totalCount || result.length);
        setIsLoading(false);
        setIsDisplay(true);
      })
      .catch(() => {
        setIsLoading(false);
        setTableRowsData([]);
        setTotalCount(0);
        setTotalGrantedAmount(0)
        setTotalRequestedAmount(0)
      });
  };

  const onNewTask = () => {
    navigate("/AddBudget", {
      state: {
        data: {},
      },
    });
  };

  const onEdit = (task) => {
    navigate("/AddBudget", {
      state: {
        data: {
          task: {
            ...task,
          },
        },
        isEdit: true,
      },
    });
  };
  const onView = (task) => {
    navigate("/budget-stepper-form", {
      state: {
        data: {
          task: {
            ...task,
          },
        },
        isView: true,
      },
    });
  };
  const onStepperForm = (task) => {
    navigate("/budget-stepper-form", {
      state: {
        data: {
          task: {
            ...task,
          },
        },
        isView: false,
      },
    });
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}

      <form>
        <div className="flex flex-col gap-2 lg:flex-row lg:justify-end">
          <div className="flex flex-col lg:flex-row">
            <div className="w-full lg:w-48 lg:mr-3">
              <Button
                className="w-full lg:w-48 lg:mr-3"
                type="button"
                onClick={() => onNewTask()}
                variant="contained"
                color="info"
                startIcon={<IoMdAdd />}
              >
                Add Request
              </Button>
            </div>
          </div>
        </div>
      </form>
      <BudgetList
        tableRowsData={tableRowsData}
        getListData={fetchListData}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
        totalGrantedAmount={totalGrantedAmount}
        totalRequestedAmount={totalRequestedAmount}
        page={page}
        setPage={setPage}
        onEdit={onEdit}
        onView={onView}
        onStepperForm={onStepperForm}
        register={register}
        errors={errors}
      />
    </>
  );
}
