import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import InputField from '../../../Common/InputField';
import CheckboxField from '../../../Common/CheckboxField';
import { Grid, Button } from '@mui/material';
import TicketCharter from '../../Ticket-Notes/TicketCharter';
import DateField from '../../../Common/DateField';

import {
  postRequest,
  putRequest,
  getRequest,
} from '../../interceptor/interceptor';
import FullScreenLoader from '../../Loader/FullScreenLoader';
import {
  UPDATE_BUDGET_NEWS,
  ADD_BUDGET_NEWS,
  UNIQUE_ID,
} from '../../../apiConst';
import { useLocation, useNavigate } from 'react-router';
import SelectDropdown from '../../../Common/SelectDropdown'
import { budgetRequestFields,budgetPersonFields } from '../../../Fields/budgetFields';
import CkEditorComponent from '../../../Common/CkEditor';
import moment from 'moment';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { toast } from 'react-toastify';

const AddBudget = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const fields = [
    ...budgetRequestFields,
    ...budgetPersonFields,


  ];
  const [id, setId] = useState(null);
  const [requestNumber, setRequestNumber] = useState(
    location?.state?.data?.task?.requestNumber || null
  );
  const [isEdit, setIsEdit] = useState(null);

  
  const [isTicketToggle, setIsTicketToggle] = useState(true); // State to toggle the accordion
  const [isRequestToggle, setIsRequestToggle] = useState(false); // State to toggle the accordion

  const ticketToggleAccordion = () => {
    setIsTicketToggle(!isTicketToggle);
    setIsRequestToggle(false);
  };

  const requestToggleAccordion = () => {
    setIsRequestToggle(!isRequestToggle);
    setIsTicketToggle(false);
  
  };

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    control,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    if (location?.state?.isEdit || location?.state?.isView) {
      location?.state?.isEdit && setIsEdit(location?.state?.isEdit);
      const { task } = location?.state?.data;
      console.log('task', task);
      // Set form fields using setValue
      fields.map((field) => setValue(field.fieldName, task[field.fieldName]));
      
    } else {
      // If neither edit nor view, generate unique ID or perform other logic
      getUniqueId();
    }
  }, []);

  

  const onCancel = () => {
    navigate('/budget', {});
  };
  const getUniqueId = () => {
    setIsLoading(true);
    getRequest(`${UNIQUE_ID}${'FIN'}`)
      .then((res) => {
        console.log('res', res);
        const { data } = res;
        if (data) {
          setValue('requestNumber', data);
          setRequestNumber(data);
        ;
          setIsLoading(false);
        } else {
          setIsLoading(true);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  
  const onSubmit = (data, e) => {
    console.log('e', e.target.value);
    console.log('data', data);
    let url = '';
    setIsLoading(true);
    const payload={
      ...data,
      module:"budget",
      requestedAmount:Number(data.requestedAmount),
      grantedAmount:Number(data.grantedAmount),
      requestedDate: moment(data.requestedDate).format('YYYY-MM-DD'),
      grantedDate: moment(data.grantedDate).format('YYYY-MM-DD')
    }
        if (isEdit) {
          url = putRequest(`${UPDATE_BUDGET_NEWS}`, payload);
        } else {
          url = postRequest(`${ADD_BUDGET_NEWS}`, payload);
        }
        url
          .then((res) => {
            console.log('res', res);
            toast.info(res);
            setIsLoading(false);
            navigate('/budget');
          })
          .catch(() => {
            setIsLoading(false);
          });
        setIsLoading(false);
      
  };

  

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <div className="block rounded-lg bg-white p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] m-4 overflow-auto">
        <div className="flex mb-2 text-xl font-extrabold text-primary-600">
          {isEdit ? 'Edit' : location?.state?.isView ? 'View' : 'Add'} Request
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div
                className="flex justify-between items-center text-white rounded-md bg-gray-700 p-2 mb-1 cursor-pointer"
                onClick={ticketToggleAccordion} // Toggle on click
              >
                <h2>Request Information</h2>
                {/* Render the icon based on the toggle state */}
                {isTicketToggle ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </Grid>
            {isTicketToggle &&
              budgetPersonFields?.map((field) => {
                const {
                  fieldTitle,
                  fieldName,
                  fieldType,
                  validationObj,
                  options,
                } = field;

                return (
                  <>
                    <Grid item xs={12} lg={3} key={fieldName}>
                      {fieldType === 'date' && fieldName === 'issueOpen' ? (
                        <>
                          <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                            {fieldTitle}
                          </label>
                          <DateField
                            fieldName={fieldName}
                            validationObj={validationObj}
                            control={control}
                            Controller={Controller}
                            minDate={new Date()}
                  
                            errors={errors}
                            defaultValue={getValues(fieldName)}
                            disabled={location?.state?.isView}
                          />
                        </>
                      ) : fieldType === 'date' &&
                        fieldName === 'targetResolution' ? (
                        <>
                          <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                            {fieldTitle}
                          </label>
                          <DateField
                            fieldName={fieldName}
                            validationObj={validationObj}
                            control={control}
                            Controller={Controller}
                            // minDate={issueOpen}
                            errors={errors}
                            defaultValue={getValues(fieldName)}
                            disabled={location?.state?.isView}
                          />
                        </>
                      ) : (
                        <>
                          <label className="block text-gray-800 text-sm font-bold mb-3 pr-4">
                            {fieldTitle}
                          </label>
                          <InputField
                            fieldName={fieldName}
                            validationObj={validationObj}
                            register={register}
                            disabled={
                              fieldName === 'requestNumber'
                                ? true
                                : location?.state?.isView
                            }
                            errors={errors}
                            fieldType={fieldType}
                          />
                        </>
                      )}
                    </Grid>
                  </>
                );
              })}
          </Grid>
          
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div
                className="flex justify-between items-center text-white rounded-md bg-gray-700 p-2 mb-1 cursor-pointer"
                onClick={requestToggleAccordion} // Toggle on click
              >
                <h2> Request Details</h2>
                {/* Render the icon based on the toggle state */}
                {isRequestToggle ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </Grid>
            {isRequestToggle &&
              budgetRequestFields.map((field) => {
                const {
                  fieldTitle,
                  fieldName,
                  fieldType,
                  validationObj,
                  options,
                } = field;

                return (
                  <Grid
                    item
                    xs={12}
                    lg={
                     
                        3
                    }
                    key={fieldName}
                  >
                    {fieldType === 'date' ? (
                      <>
                        <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                          {fieldTitle}
                        </label>
                        <DateField
                          fieldName={fieldName}
                          validationObj={validationObj}
                          control={control}
                          Controller={Controller}
                          minDate={new Date()}
                          errors={errors}
                          defaultValue={getValues(fieldName)}
                          disabled={location?.state?.isView}
                        />
                      </>
                    ) : fieldType === 'editor' ? (
                      <>
                        <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                          {fieldTitle}
                        </label>
                        <CkEditorComponent
                          register={register}
                          fieldName={fieldName}
                          control={control}
                          data={getValues(fieldName) || ''}
                          setValue={setValue}
                          disabled={location?.state?.isView}
                        />
                      </>
                    )  : (
                      <>
                        <label className="block text-sm text-gray-800 font-bold mb-3 pr-4">
                          {fieldTitle}
                        </label>
                        <InputField
                          fieldName={fieldName}
                          validationObj={validationObj}
                          register={register}
                          disabled={
                            fieldName === 'requestNumber'
                              ? true
                              : location?.state?.isView
                          }
                          errors={errors}
                          fieldType={fieldType}
                        />
                      </>
                    )}
                  </Grid>
                );
              })}
          </Grid>
          <TicketCharter
            taskId={id}
            ticketNo={requestNumber}
            isView={location?.state?.isView}
            noteStatus="inprogress"
          />

          <div className="flex flex-col lg:flex-row items-center justify-center space-x-4">
            <Grid item>
              <Button
                variant="contained"
                color="error"
                onClick={onCancel}
                className="mt-5"
              >
                {location?.state?.isView ? 'Back' : 'Cancel'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                className="mt-5"
                disabled={location?.state?.isView}
                value="save"
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="mt-5"
                disabled={location?.state?.isView}
                value="submit"
                onClick={handleSubmit(onSubmit)}
              >
                {isEdit ? 'Update' : 'Save & Submit'}
              </Button>
            </Grid>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddBudget;
