import { useTranslation } from "react-i18next";
import Navbar from "../Navbar/navbar";
import SecondFooter from "../Footer/SecondFooter";
const About = () => {
  const { t } = useTranslation();
  return (
    <>
    <Navbar/>
    
    <section id="about" className="px-5">
      <div className="container mx-auto mb-12">
        {/* Header Section */}
        <div className="text-center mt-12 mb-12">
          <h2 className="text-4xl font-bold font-heading text-gray-800 mb-4">
            About Me
          </h2>
          <p className="text-gray-700 font-paragraph text-lg">
          Learn more about who I am, my journey, and what drives me. <br />
          My story is one of passion, dedication, and a commitment to making a positive impact.
          </p>
        </div>
        <div className="flex flex-col justify-between lg:grid lg:grid-cols-2 lg:gap-2">
          <img
            src="https://taskresultspro.s3.amazonaws.com/slide4.png"
            alt="ram"
            className="rounded-lg h-[420px]"
          />
          <p className="text-xl text-gray-800 lg:pl-4 leading-relaxed text-justify font-paragraph">
            {t("about")}
          </p>
        </div>
      </div>
    </section>
    <SecondFooter/>
    </>
  );
};
export default About;
