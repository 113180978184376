import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { getRequest, postRequest } from '../interceptor/interceptor';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { RiProgress6Line } from 'react-icons/ri';
import { IoBarChart } from 'react-icons/io5';
import CircularChart from '../CircularChart/CircularChart';
import BarChart from '../BarChart/BarChart';
import NavigationTab from '../NavigationTab/NavigationTab.jsx';
import {
  Grid,
  Card,
  Paper,
  Container,
  CardHeader,
  Typography,
} from '@mui/material';
import { GET_TICKETS, TICKET_STATS } from '../../apiConst';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TicketsList from './TicketsList';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { dashboardTableHeader } from '../../Fields/grievanceFields';
import SelectDropdown from '../../Common/SelectDropdown';

const Dashboard = () => {
  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const headers = dashboardTableHeader;

  const userData = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(rowsPerPage);
  const [tableRowsData, setTableRowsData] = useState([]);
  const [filterByText, setFilterByText] = useState('');
  const [tabsData, setTabsData] = useState([]);
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [newCount, setNewCount] = useState(0);
  const [inProgressCount, setInProgressCount] = useState(0);
  const [resolvedCount, setResolvedCount] = useState(0);
  const [onHoldCount, setOnHoldCount] = useState(0);
  const [newTicketsData, setNewTicketsData] = useState([]);
  const [inProgressData, setInProgressData] = useState([]);
  const [resolvedData, setResolvedData] = useState([]);
  const [onHoldData, setOnHoldData] = useState([]);
  const navigate = useNavigate();
  const [searchVal, setSearchVal] = useState('');

  useEffect(() => {
    fetchListData();
    import('../../utils/taskNavTabs.js').then((data) => {
      setTabsData(data.taskNavTabs);
    });
  }, [startDate, endDate]);

  const fetchListData = (pg = 1, size = 10, search = '', searchKey = null) => {
    setIsLoading(true);

    let payload = {
      startDate: startDate,
      endDate: endDate,
    };

    const { searchField } = getValues();

    const key = searchKey ? searchKey : searchField;
    if (key && search) {
      payload[key] = search;
    }

    postRequest(TICKET_STATS, payload)
      .then((res) => {
        console.log('res', res);
        const {
          newTicket_count,
          inprogress_count,
          resolved_count,
          onHold_count,
          total,
          newTicket,
          inprogress,
          onHold,
          resolved,
        } = res.data;
        // setTableRowsData(result || []);
        setTotalCount(total);
        setNewCount(newTicket_count);
        setInProgressCount(inprogress_count);
        setResolvedCount(resolved_count);
        setNewTicketsData(newTicket || []);
        setInProgressData(inprogress || []);
        setResolvedData(resolved || []);
        setOnHoldCount(onHold_count);
        setOnHoldData(onHold || []);
        setIsLoading(false);
        setIsDisplay(true);
      })
      .catch(() => {
        setIsLoading(false);
        setNewCount(0);
        setInProgressCount(0);
        setResolvedCount(0);
        setNewTicketsData([]);
        setInProgressData([]);
        setResolvedData([]);
        setTotalCount(0);
      });
  };

  const onEdit = (task) => {
    navigate('/stepper-form', {
      state: {
        data: {
          task: {
            ...task,
          },
        },
        isEdit: true,
      },
    });
  };
  const onView = (task) => {
    navigate('/stepper-form', {
      state: {
        data: {
          task: {
            ...task,
          },
        },
        isView: true,
      },
    });
  };
  const onStepperForm = (task) => {
    navigate('/stepper-form', {
      state: {
        data: {
          task: {
            ...task,
          },
        },
        isView: false,
      },
    });
  };

  const handleSearchFieldDropdown = (e) => {
    setValue('searchField', e.target.value);
    if (searchVal) {
      fetchListData(1, rowsPerPage, searchVal, e.target.value);
    }
  };

  const debounceSearch = debounce((searchValue) => {
    setSearchVal(searchValue);
    fetchListData(1, rowsPerPage, searchValue);
  }, 750);

  const handleChangeSearch = (e) => {
    const { value } = e.target;
    debounceSearch(value);
  };

  const cancelSearch = () => {
    setSearchVal('');
    fetchListData(1, rowsPerPage, '');
  };

  const componentDisplay = () => {
    const cells = headers?.filter(
      (cell) =>
        cell.id == 'requestType' ||
        cell.id === 'assignedTo' ||
        cell.id === 'referralName'
    );
    return (
      <div className="text-center mb-[-15px]">
        {headers.length > 0 && (
          <SelectDropdown
            fieldName="searchField"
            options={cells}
            optionsLabelKey="label"
            optionsValueKey="id"
            register={register}
            onChange={handleSearchFieldDropdown}
            errors={errors}
            fieldType="select"
            className="min-h-[auto] ml-3 w-full border-1 bg-light py-2 text-sm text-gray-900 bg-white-900 rounded-md pl-2 focus:outline-none focus:bg-white focus:text-gray-900"
            emptyOption={false}
          />
        )}
      </div>
    );
  };

  // const data = [
  //   {
  //     attr1: "value1_attr1",
  //     attr2: "value1_attr2",
  //     //...
  //   },
  //   {
  //     attr1: "value2_attr1",
  //     attr2: "value2_attr2",
  //     //...
  //   },
  // ];

  // const [setDataState, dataState] = useState();

  return (
    <Container maxWidth="xl" sx={{ py: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <label className="block text-gray-800 font-bold mb-3 pr-4">
            Start Date
          </label>
          <DatePicker
            selected={moment(startDate).format('YYYY-MM-DD')}
            onChange={(date) => setStartDate(moment(date).format('YYYY-MM-DD'))}
            dateFormat="dd/MM/yyyy"
            placeholderText="Select Date"
            className="shadow appearance-none border border-gray-700 rounded-xl w-full  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-300 disabled:bg-gray-200"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <label className="block text-gray-800 font-bold mb-3 pr-4">
            End Date
          </label>
          <DatePicker
            selected={moment(endDate).format('YYYY-MM-DD')}
            onChange={(date) => setEndDate(moment(date).format('YYYY-MM-DD'))}
            dateFormat="dd/MM/yyyy"
            placeholderText="Select Date"
            className="shadow appearance-none border border-gray-700 rounded-xl w-full  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-300 disabled:bg-gray-200"
            showMonthDropdown
            showYearDropdown
            minDate={startDate}
            dropdownMode="select"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <label className="block text-gray-800 font-bold mb-3">
            Filter by
          </label>
          {componentDisplay ? componentDisplay() : <div className=""></div>}
        </Grid>
        <Grid item xs={12} md={3}>
          <label className="block text-gray-800 font-bold mb-3">Search</label>
          <div className="relative text-gray-600 focus-within:text-gray-400">
            <span className="absolute inset-y-0  flex items-center">
              <button
                type="submit"
                className="p-1 focus:outline-none focus:shadow-outline"
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  className="w-6 h-6"
                >
                  <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
              </button>
            </span>
            <input
              type="search"
              name="search"
              onChange={(e) => handleChangeSearch(e)}
              className="py-2 text-sm mt-2 lg:mt-0 lg:w-48 text-gray-900 bg-white-900 rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900"
              placeholder="Search..."
            />
          </div>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <Card
            className="flex-auto flex justify-between items-center h-20 m-2 pl-10 pr-10 p-1"
            style={{
              background: 'linear-gradient(-20deg,#f89650 0,#f84450 100%)',
            }}
          >
            <div className="flex items-center">
              <Typography
                variant="h6"
                className="text-base lg:text-xl font-bold text-white uppercase"
              >
                New
              </Typography>
            </div>
            <div className="h-full bg-gray-200 w-1 rounded"></div>
            <Typography variant="h6" className="text-2xl font-bold text-white">
              {newCount}
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card
            className="flex-auto flex justify-between items-center h-20 m-2 pl-10 pr-10 p-1"
            style={{
              background: 'linear-gradient(-20deg,#3457D5 0,#0039a6 100%)',
            }}
          >
            <div className="flex items-center">
              <Typography
                variant="h6"
                className="text-base lg:text-xl font-bold text-white uppercase"
              >
                In Progress
              </Typography>
            </div>
            <div className="h-full bg-gray-200 w-1 rounded"></div>
            <Typography variant="h6" className="text-2xl font-bold text-white">
              {inProgressCount}
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card
            className="flex-auto flex justify-between items-center h-20 m-2 pl-10 pr-10 p-1"
            style={{
              background: 'linear-gradient(-20deg,#FFBF00 0,#a05544 100%)',
            }}
          >
            <div className="flex items-center">
              <Typography
                variant="h6"
                className="text-base lg:text-xl font-bold text-white uppercase"
              >
                On Hold
              </Typography>
            </div>
            <div className="h-full bg-gray-200 w-1 rounded"></div>
            <Typography variant="h6" className="text-2xl font-bold text-white">
              {/* {resolvedCount} */}0
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card
            className="flex-auto flex justify-between items-center h-20 m-2 pl-10 pr-10 p-1"
            style={{
              background: 'linear-gradient(-20deg,#aabf72 0,#22b573 100%)',
            }}
          >
            <div className="flex items-center">
              <Typography
                variant="h6"
                className="text-base lg:text-xl font-bold text-white uppercase"
              >
                Resolved
              </Typography>
            </div>
            <div className="h-full bg-gray-200 w-1 rounded"></div>
            <Typography variant="h6" className="text-2xl font-bold text-white">
              {resolvedCount}
            </Typography>
          </Card>
        </Grid>

        {/* <PivotTableUI
          data={data}
          onChange={(s) => setDataState(s)}
          {...dataState}
        /> */}
        <Grid item xs={12} lg={6}>
          <Paper elevation={2} sx={{ height: 230, overflowY: 'auto' }}>
            <CardHeader
              style={{ backgroundColor: '#033d62', padding: '5px' }}
              title={
                <Typography
                  variant="h6"
                  style={{
                    fontSize: '16px',
                    color: '#edf4fa',
                    fontWeight: 600,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <RiProgress6Line className="mr-2 h-4 w-4" />
                  Ticket Chart
                </Typography>
              }
            />
            <CircularChart
              newTickets={newCount}
              inProgress={inProgressCount}
              onHold={0}
              resolved={resolvedCount}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper elevation={2} sx={{ height: 230, overflowY: 'auto' }}>
            <CardHeader
              style={{ backgroundColor: '#033d62', padding: '5px' }}
              title={
                <Typography
                  variant="h6"
                  style={{
                    fontSize: '16px',
                    color: '#edf4fa',
                    fontWeight: 600,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <IoBarChart className="mr-2 h-4 w-4" />
                  Ticket Stats
                </Typography>
              }
            />
            <BarChart
              newTickets={newCount}
              inProgress={inProgressCount}
              resolved={resolvedCount}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Paper elevation={2} sx={{ height: 600, overflowY: 'auto' }}>
            <CardHeader
              style={{ backgroundColor: '#033d62', padding: '5px' }}
              title={
                <Typography
                  variant="h6"
                  style={{
                    fontSize: '16px',
                    color: '#edf4fa',
                    fontWeight: 600,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Tickets
                </Typography>
              }
            />
            <NavigationTab
              navTabs={tabsData}
              heading="Tickets"
              tab_1={
                <TicketsList
                  tableRowsData={newTicketsData}
                  getListData={fetchListData}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  totalCount={totalCount}
                  page={page}
                  setPage={setPage}
                  onEdit={onEdit}
                  onView={onView}
                  onStepperForm={onStepperForm}
                  register={register}
                  errors={errors}
                />
              }
              tab_2={
                <TicketsList
                  tableRowsData={inProgressData}
                  getListData={fetchListData}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  totalCount={totalCount}
                  page={page}
                  setPage={setPage}
                  onEdit={onEdit}
                  onView={onView}
                  onStepperForm={onStepperForm}
                  register={register}
                  errors={errors}
                />
              }
              tab_3={
                <TicketsList
                  tableRowsData={onHoldData}
                  getListData={fetchListData}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  totalCount={totalCount}
                  page={page}
                  setPage={setPage}
                  onEdit={onEdit}
                  onView={onView}
                  onStepperForm={onStepperForm}
                  register={register}
                  errors={errors}
                />
              }
              tab_4={
                <TicketsList
                  tableRowsData={resolvedData}
                  getListData={fetchListData}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  totalCount={totalCount}
                  page={page}
                  setPage={setPage}
                  onEdit={onEdit}
                  onView={onView}
                  onStepperForm={onStepperForm}
                  register={register}
                  errors={errors}
                />
              }
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
