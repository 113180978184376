import React from "react";
const abilitiesData = [
  {
    id: 1,
    title: "Visionary Leadership in Aviation",
    description:
      "Kinjavarapu Rammohan Naidu has been instrumental in transforming India's aviation sector. With a focus on sustainable growth and modernization, he is shaping policies that drive innovation and development in the industry.",
    imageSrc: 'https://hbt-seva.s3.ap-south-1.amazonaws.com/vision.jpg'
  },
  {
    id: 2,
    title: "Modernizing Airports Nationwide",
    description:
      "Under his leadership, efforts have been intensified to upgrade infrastructure across major and regional airports, ensuring world-class facilities and seamless experiences for passengers.",
    imageSrc: 'https://hbt-seva.s3.ap-south-1.amazonaws.com/modernization.jpg',
  },
  {
    id: 3,
    title: "Boosting Regional Connectivity",
    description:
      "Promoting the UDAN initiative, he has prioritized connecting underserved regions through affordable and efficient air travel, bridging gaps and fostering economic growth.",
    imageSrc: 'https://hbt-seva.s3.ap-south-1.amazonaws.com/connectivity.jpg',
  },
];

const Abilities = () => {
  return (
    <div className="flex flex-col lg:flex-row items-center justify-between gap-8 p-8">
      {/* Left Section */}
      <div className="lg:w-1/2">
        <h2 className="text-4xl font-heading text-gray-800 font-bold mb-2">
          Kinjavarapu Rammohan Naidu: Achievements in Aviation
        </h2>
        <p className="text-lg font-paragraph text-gray-700 mb-3">
          As the Central Aviation Minister, Kinjavarapu Rammohan Naidu has championed initiatives to modernize the aviation industry, expand connectivity, and foster innovation. His vision is creating a future-ready aviation sector that empowers the nation.
        </p>
        <img
          src='https://hbt-seva.s3.ap-south-1.amazonaws.com/aviation.jpg'
          alt="Aviation Achievements"
          className="w-full h-[360px]"
        />
      </div>

      {/* Right Section */}
      <div className="lg:w-1/2 grid grid-cols-1 gap-6">
        {abilitiesData.map((ability) => (
          <div
            key={ability.id}
            className="flex flex-col md:flex-row items-center gap-4 border border-gray-600"
          >
            <div className="w-full md:w-2/3 p-2">
              <h3 className="text-xl text-gray-800 font-heading font-bold mb-2">
                <span className="text-blue-900 text-2xl">+</span> {ability.title}
              </h3>
              <p className="text-lg font-paragraph text-gray-700">{ability.description}</p>
            </div>
            <div className="w-full md:w-1/3">
              <img
                src={ability.imageSrc}
                alt={ability.title}
                className="w-full h-[180px] object-cover"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Abilities;
