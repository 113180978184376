import React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import "../../css/table.css";
import { get } from "lodash";
import { getComparator, stableSort, TableComponent } from "../../Common/Table";
import { useState } from "react";
import { Tooltip } from "@mui/material";
import { IoEye } from "react-icons/io5";
import SelectDropdown from "../../Common/SelectDropdown";
import { newsTableHeader } from "../../Fields/newsFields";
import { useMediaQuery } from "react-responsive";
import TableCard from "../../Common/TableCard";
export default function NewsList({
  tableRowsData,
  getListData,
  rowsPerPage,
  setRowsPerPage,
  totalCount,
  page,
  setPage,
  onEdit,
  onView,
  onStepperForm,
  register,
  errors,
}) {
  const isTabletMid = useMediaQuery({ query: "(max-width: 768px)" });
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [searchVal, setSearchVal] = useState("");
  const headers = newsTableHeader;

  const handleChangePage = async (event, newPage) => {
    console.log("new page: " + newPage);
    setPage(newPage);
    await getListData(Number(newPage + 1), rowsPerPage, "");
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("event" + event.target.value);
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    getListData(1, parseInt(event.target.value), "");
  };

  const handleSearchFieldDropdown = (e) => {
    if (searchVal) {
      getListData(1, rowsPerPage, searchVal, e.target.value);
    }
  };

  const componentDisplay = () => {
    const cells = headers?.filter(
      (cell) =>
        cell.id === "newsId" ||
        cell.id === "news" ||
        cell.id === "title" ||
        cell.id === "source" ||
        cell.id === "link"
    );
    return (
      <div className="lg:mr-3 lg:w-52 text-center mb-[-15px] w-96">
        {headers.length > 0 && (
          <SelectDropdown
            fieldName="searchField"
            options={cells}
            optionsLabelKey="label"
            optionsValueKey="id"
            register={register}
            onChange={handleSearchFieldDropdown}
            errors={errors}
            fieldType="select"
            className="min-h-[auto] ml-3 w-48 border-1 bg-light py-2 text-sm text-gray-900 bg-white-900 rounded-md pl-2 focus:outline-none focus:bg-white focus:text-gray-900"
            emptyOption={false}
          />
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="taskList mx-2 shadow-lg">
        {isTabletMid ? (
          <div>
            <div className="grid grid-cols-1 pt-2 pb-2">
              <TableComponent
                numSelected={0}
                searchVal={searchVal}
                headingText={"News Details"}
                order={order}
                orderBy={orderBy}
                rowCount={tableRowsData.length || 0}
                tableRowsData={tableRowsData}
                headCells=""
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                setSearchVal={setSearchVal}
                rowsPerPage={rowsPerPage}
                getAllData={getListData}
                // componentDisplay={componentDisplay}
              >
                {tableRowsData &&
                  tableRowsData.length > 0 &&
                  tableRowsData.map((data) => (
                    <TableCard
                      key={data.newsId}
                      newsId={data.newsId}
                      title={data.title}
                      description={data?.description}
                      link={data?.link}
                      status={data.status}
                      onEdit={(e) => onStepperForm(data, e)}
                      onView={(e) => onStepperForm(data, e)}
                    />
                  ))}
              </TableComponent>
              {/* <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Entries per page: "}
                labelDisplayedRows={({ from, to, count }) =>
                  `Showing ${from}-${to} of ${count} entries`
                }
              /> */}
            </div>
          </div>
        ) : (
          <div style={{ width: "100%" }}>
            <TableComponent
              numSelected={0}
              searchVal={searchVal}
              headingText={"News Details"}
              order={order}
              searchBar={true}
              orderBy={orderBy}
              rowCount={tableRowsData.length || 0}
              tableRowsData={tableRowsData}
              headCells={headers}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              setSearchVal={setSearchVal}
              rowsPerPage={rowsPerPage}
              getAllData={getListData}
              componentDisplay={componentDisplay}
            >
              <TableBody className="table-body">
                {tableRowsData &&
                  tableRowsData.length > 0 &&
                  stableSort(tableRowsData, getComparator(order, orderBy)).map(
                    (row, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={index}>
                          <TableCell>
                            {/* <input type='checkbox'></input> */}
                          </TableCell>

                          {headers.map((cell) => {
                            return (
                              <TableCell key={cell.id + index}>
                                {cell.id === "action" ? (
                                  <div className="justify-around items-center flex">
                                    {/* <div onClick={(e) => onEdit(row)}>
                                      <FaEdit size={20}/>
                                    </div> */}
                                    <div onClick={(e) => onView(row, e)}>
                                      <IoEye size={23} />
                                    </div>
                                  </div>
                                ) : cell.id === "newsId" ? (
                                  <div className="justify-center flex cursor-pointer underline text-blue-800 hover:font-bold">
                                    <div onClick={(e) => onStepperForm(row, e)}>
                                      {get(row, cell.id)}
                                    </div>
                                  </div>
                                ) : cell.id === "status" ? (
                                  <div
                                    className={`justify-center flex capitalize
                                     hover:font-bold`}
                                  >
                                    {get(row, cell.id) === "inprogress"
                                      ? "In Progress"
                                      : get(row, cell.id)}
                                  </div>
                                ) : (
                                  <Tooltip title={get(row, cell.id)}>
                                    <span>
                                      {get(row, cell.id)?.length > 30
                                        ? `${get(row, cell.id)?.slice(0, 30)}...`
                                        : get(row, cell.id)}
                                    </span>
                                  </Tooltip>
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    }
                  )}
              </TableBody>
            </TableComponent>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={"Entries per page: "}
              labelDisplayedRows={({ from, to, count }) =>
                `Showing ${from}-${to} of ${count} entries`
              }
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
