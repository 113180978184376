import { Card, Typography } from "@material-tailwind/react";
import { centralTeam, stateTeam } from "../../Fields/team";
import Navbar from "../Navbar/navbar";
import SecondFooter from "../Footer/SecondFooter";

function Team() {
  const TABLE_HEAD = ["Designation", "Officer Name", "Email", "Telephone"];

  const TABLE_ROWS = centralTeam;

  const TABLE_STATE_ROWS = stateTeam;

  return (
    <>
      <Navbar />
      <section className="px-5">
        <div className="container mx-auto mb-12">
          {/* Header Section */}
          <div className="text-center mt-12 mb-12">
            <h2 className="text-4xl font-bold font-heading text-gray-800 mb-4">
              The Team
            </h2>
            <p className="text-gray-700 font-paragraph text-lg">
            Meet the people who drive our success and share our vision.
            </p>
          </div>
          <div className="mt-2 mb-2">
            <h2 className="text-xl lg:text-3xl mb-4">Minister's Office</h2>
            <Card className="h-full w-full overflow-scroll">
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className="border-b border-blue-gray-100 bg-[#1c263c] p-4"
                      >
                        <Typography
                          variant="lead"
                          color="white"
                          className="font-normal leading-none"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {TABLE_ROWS.map(
                    ({ Designation, Name, Email, Phone }, index) => (
                      <tr key={Email} className="even:bg-blue-gray-50/50">
                        <td className="p-4">
                          <Typography
                            variant="paragraph"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {Designation}
                          </Typography>
                        </td>
                        <td className="p-4">
                          <Typography
                            variant="paragraph"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {Name}
                          </Typography>
                        </td>
                        <td className="p-4">
                          <Typography
                            variant="paragraph"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {Email}
                          </Typography>
                        </td>
                        <td className="p-4">
                          <Typography
                            variant="paragraph"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {Phone.join(", ")}
                          </Typography>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </Card>
          </div>
          <div className="mt-2 mb-2">
            <h2 className="text-xl lg:text-3xl mb-4">Secretary's Office</h2>
            <Card className="h-full w-full overflow-scroll">
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className="border-b border-blue-gray-100 bg-[#1c263c] p-4"
                      >
                        <Typography
                          variant="lead"
                          color="white"
                          className="font-normal leading-none"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {TABLE_STATE_ROWS.map(
                    ({ Designation, Name, Email, Phone }, index) => (
                      <tr key={Email} className="even:bg-blue-gray-50/50">
                        <td className="p-4">
                          <Typography
                            variant="paragraph"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {Designation}
                          </Typography>
                        </td>
                        <td className="p-4">
                          <Typography
                            variant="paragraph"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {Name}
                          </Typography>
                        </td>
                        <td className="p-4">
                          <Typography
                            variant="paragraph"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {Email}
                          </Typography>
                        </td>
                        <td className="p-4">
                          <Typography
                            variant="paragraph"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {Phone.join(", ")}
                          </Typography>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </Card>
          </div>
        </div>
      </section>
      <SecondFooter />
    </>
  );
}

export default Team;
