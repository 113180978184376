import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { FaUser } from "react-icons/fa";
import { userLogoutOp } from "../../redux/operations/user.operations";

const Profile = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { user } = useSelector((state) => state || {});
  const initials = user?.firstName?.charAt(0)?.toUpperCase() || user?.lastName?.charAt(0)?.toUpperCase() 
    ? `${user?.firstName?.charAt(0)?.toUpperCase() || ""}${user?.lastName?.charAt(0)?.toUpperCase() || ""}` 
    : <FaUser size={24}/>;
  const name = `${user?.firstName || ""} ${user?.lastName || ""}`.trim();
  const email = user?.email || "";
  const role = user?.role || "N/A"; // Adjust default role as necessary
  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleProfileClick = () => {
    setShowMenu((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    if (showMenu) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showMenu]);

  const handleLogout = () => {
    dispatch(userLogoutOp());
    localStorage.removeItem("userAuthToken");
    localStorage.removeItem("locationIp");
    navigate("/login");
  };

  return (
    <div className="relative inline-block mt-1 mr-2 ml-2" ref={menuRef}>
      <div className="cursor-pointer" onClick={handleProfileClick}>
        <div className="flex items-center">
          <div className="w-9 h-9 rounded-full p-1 bg-gray-500 flex items-center justify-center text-white font-bold">
            <p className="text-sm uppercase pt-4">{initials}</p>
          </div>
        </div>
      </div>

      {showMenu && (
        <div
          className="origin-top-right z-50 absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu-button"
          tabIndex="-1"
        >
          <div className="flex justify-center mt-3 items-center">
            <div className="w-24 h-24 rounded-full p-1 bg-gray-500 flex items-center justify-center text-white font-bold">
              <p className="text-md uppercase">{initials}</p>
            </div>
          </div>

          <div className="py-4 text-center border-b border-gray-200">
            <p className="text-lg font-semibold py-2 text-gray-800">
              {name || ""}
            </p>
            <p className="text-sm text-gray-700 font-bold py-2">{email}</p>
            <p className="text-sm text-gray-700 font-bold">{role}</p>
          </div>
          <div className="py-1 flex justify-center items-center">
            <button
              className="block px-4 py-2 bg-red-600 text-sm text-gray-100 rounded-md text-center"
              onClick={handleLogout}
            >
              Sign out
            </button>
          </div>
          {/* <div className="border-t flex justify-between border-gray-200 mt-1 p-2">
            <p className="block text-sm text-gray-400 hover:text-blue-700 cursor-pointer">
              Privacy Policy
            </p>
            <p className="block text-sm text-gray-400 hover:text-blue-700 cursor-pointer">
              Terms of Service
            </p>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default Profile;
