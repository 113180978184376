export const GENERATE_OTP = '/static/generateOtp';
export const VERIFY_OTP = '/static/verify';

export const GET_IP_ADDRESS = 'https://api.ipify.org/?format=json';
export const DOCUMENT_UPLOAD = '/file/upload?bucket=hbt-seva';

export const ADD_NOTES_DETAILS = '/notes/add';
export const EDIT_NOTES_DETAILS = '/notes/edit';
export const GET_NOTES_DETAILS = '/notes/get';

//Tickets

export const ADD_TICKET = '/ticket/add';
export const GET_TICKETS = '/ticket/list';
export const UPDATE_TICKET = '/ticket/edit';
export const DELETE_TICKET = '/ticket/delete?ticketNo=';
export const UNIQUE_ID = '/generic/uniqueid?prefix=';
export const REFERRAL_DATA_BY_CONTACT = '/ticket/ref-contact?number=';
export const GET_NOTES_BY_TICKET = '/ticket/byId?ticketNo=';

export const GET_NOTES = '/notes/list';
export const ASSIGNED_TO = '/ticket/handler';
export const TTD_DATES = '/ticket/ttd-dates';

export const TICKET_STATS = '/ticket/status-count';

export const GET_CALENDAR_EVENTS = '/calender/event-list';

export const CREATE_CALENDAR_EVENT = '/calender/add-event';

export const UPDATE_CALENDAR_EVENT = '/calender/update-event';

export const DELETE_CALENDAR_EVENT = '/calender/delete?eventId=';

export const GET_AUDIT_HISTORY = '/generic/audit-history';


//budget and news


export const ADD_BUDGET_NEWS = '/generic/add-budget-news';
export const UPDATE_BUDGET_NEWS = '/generic/edit-budget-news';
export const LIST_BUDGET_NEWS = '/generic/list-budget-news';


export const LATEST_NEWS = '/static/news';


